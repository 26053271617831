class ReconnectingWebSocket {
  constructor(url, token) {
    this.url = url;
    this.token = token;
    this.reconnectInterval = 1000; // Reconnect interval in milliseconds
    this.websocket = null;
    this.messageHandlers = {};

    this.connect();
  }

  connect() {
    if (!this.token) {
      return;
    }

    this.websocket = new WebSocket(`${this.url}?token=${this.token}`);

    this.websocket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    this.websocket.onmessage = (event) => {
      const message = event.data;
      console.log('WebSocket message received:', message);

      try {
        const jsonData = JSON.parse(message);

        const objectType = jsonData?.object_type;
        const messageHandler = this.messageHandlers[objectType] || console.log;

        messageHandler(jsonData);
      } catch (error) {
        console.warn('Failed to parse received JSON:', error);
      }
    };

    this.websocket.onclose = (event) => {
      console.log('WebSocket connection closed:', event);

      // Attempt to reconnect
      setTimeout(() => {
        console.log('Reconnecting...');
        this.connect();
      }, this.reconnectInterval);
    };

    this.websocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  send(data) {
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(data);
    } else {
      console.warn('WebSocket not open. Unable to send data:', data);
    }
  }

  close() {
    if (this.websocket) {
      this.websocket.close();
    }
  }

  addHandler(objectType, handler) {
    if (!this.websocket) {
      this.connect();
    }

    this.messageHandlers[objectType] = handler;
  }

  removeHandler(objectType) {
    delete this.messageHandlers[objectType];
  }
}

const token = localStorage.getItem('token');
const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
const wsUrl = `${wsProtocol}://${window.location.hostname}:${window.location.port}/API/v1/ws`;

const websocketConnection = new ReconnectingWebSocket(wsUrl, token);
websocketConnection.send('connect');

export default websocketConnection;
