
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platforms } from "./Platforms";
import { Countries } from './Countries';
import { Verification } from './Verification';
import { Audience } from './Audience';
import { Compromised } from './Compromised';
import { Bots } from './Bots';
import { StateAffiliated } from './StateAffiliated';
import { TopActorsByPosts } from './TopActorsByPosts';
import { TopTagsByPosts } from './TopTagsByPosts';
import {BreakdownsContext} from './contexts/BreakdownsContext';
import { WidgetContentModal } from '../../../components/WidgetContentModal/WidgetContentModal';
import { TopsWidget } from './TopsWidget';
import { ampli } from '../../../ampli';
import { useCurrentUser } from '../../../contexts/CurrentUser';
import { AddToNarrativeModal } from '../../../components/AddToNarrativeModal/AddToNarrativeModal';
import { AddToSourceGroupModal } from '../../../components/AddToSourceGroupModal/AddToSourceGroupModal';
import { platformMapping } from '../../../utils/platforms';

import styles from '../NarrativePage.module.scss';

export const Breakdowns = ({isShare, narrative}) => {
  const { t } = useTranslation();

  const {
    isModalOpen,
    closeModal,
    sourceGroupFilter,
    platformsFilter,
    sourcesTypesFilter,
    countriesFilter,
    sourcesSubtypesFilter,
    affiliationCountryId,
    audienceFilter,
    sourceFilter,
  } = useContext(BreakdownsContext);

  const { currentUser } = useCurrentUser();
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const hideRiskActors = featureFlags?.includes('hide_risk_actors');
  const [addToCaseModal, setAddToCaseModal] = useState({
    isOpen: false,
    platforms: null,
    sourceGroups: null,
    sources: null,
    countries: null,
    showOnlyBots: false,
    discreditations: null,
    affiliationCountries: null,
    audienceRange: null,
    isVerified: false,
    suspendedByPlatform: false,
    immatureAccounts: false,
  });
  const [addToGroupModal, setAddToGroupModal] = useState({
    isOpen: false,
    platforms: null,
    sourceGroups: null,
    sources: null,
    countries: null,
    showOnlyBots: false,
    discreditations: null,
    affiliationCountries: null,
    audienceRange: null,
    isVerified: false,
    suspendedByPlatform: false,
    immatureAccounts: false,
  });

  useEffect(() => {
    ampli.track({
      event_type: 'Open Actors tab',
      event_properties: {
        user_id: currentUser?.id,
        workspace_id: currentUser?.workspace_id,
        narrative_id: narrative?.id,
      }
    });
  }, []);
  
  return (
    <div className={styles.breakdownsContainer}>
      <h2>{t('Breakdowns')}</h2>
      {/* <div>{t("Click on any name, digit, actor or source name to view it’s details. Or simply click the 'View details' button to view details of the widget")}</div> */}

      <div className={styles.breakdownsRow}>
        <Platforms narrative={narrative} isShare={isShare} />
        <Countries narrative={narrative} isShare={isShare} />
        <Verification narrative={narrative} isShare={isShare} />
      </div>

      <div className={styles.breakdownsRow}>
        <Audience narrative={narrative} isShare={isShare} />
      </div>

      <div className={styles.breakdownsRow}>
        <TopsWidget narrative={narrative} isShare={isShare} />
      </div>

      <h2>{t('Specific groups')}</h2>

      <div className={styles.breakdownsRow}>
        <StateAffiliated
          narrative={narrative}
          isShare={isShare}
          details={true}
        />
      </div>

      {hideRiskActors ? '' : <div className={styles.breakdownsRow}>
        <Compromised narrative={narrative} isShare={isShare} details={true} />
      </div>}

      <div className={styles.breakdownsRow}>
        <Bots narrative={narrative} isShare={isShare} details={true} />
      </div>

      {isModalOpen ? (
        <WidgetContentModal
          infoModal={isModalOpen}
          isChart={true}
          platformOption={platformsFilter}
          type={sourcesTypesFilter}
          subType={sourcesSubtypesFilter}
          stateAffiliatedId={affiliationCountryId}
          countryId={countriesFilter}
          // platformsOptions={platformOptions}
          audienceRange={audienceFilter}
          narrative={narrative}
          sourceGroup={sourceGroupFilter}
          source={sourceFilter}
          isShare={isShare}
          showAddToActions={true}
          onChange={(bool) => {
            closeModal();
          }}
          onAddToCase={(bool) => {
            setAddToCaseModal({
              isOpen: bool,
              platforms: platformsFilter
                ? [platformMapping[platformsFilter?.value]]
                : [],
              sourceGroups: sourceGroupFilter || [],
              sources: sourceFilter ? sourceFilter : [],
              audienceRange: audienceFilter,
              isVerified: sourcesTypesFilter?.value === 'is_verified',
              suspendedByPlatform: sourcesSubtypesFilter === 'suspended_by_platform',
              immatureAccounts: sourcesSubtypesFilter === 'immature_account',
              countries: countriesFilter?.map((country) => country.value),
              showOnlyBots:
                sourcesTypesFilter?.value === 'is_bot' &&
                sourcesTypesFilter?.bool,
              discreditations:
                sourcesTypesFilter?.value === 'discreditated' &&
                !sourcesSubtypesFilter
                  ? [
                      'SANCTIONS',
                      'DISINFORMATION',
                      'HACKED_OR_STOLEN',
                      'INVOLVED_IN_INFLUENCE_OPERATIONS',
                    ]
                  : sourcesTypesFilter?.value === 'discreditated' &&
                    sourcesSubtypesFilter
                  ? [sourcesSubtypesFilter]
                  : [],
              affiliationCountries:
                sourcesTypesFilter?.value === 'state_affiliated' &&
                affiliationCountryId
                  ? [{value: affiliationCountryId}]
                  : sourcesTypesFilter?.value === 'state_affiliated' &&
                    !affiliationCountryId
                  ? [{value: 'all'}]
                  : [],
            });
            closeModal()
          }}
          onAddToGroup={(bool) => {
            setAddToGroupModal({
              isOpen: bool,
              platforms: platformsFilter
                ? [platformMapping[platformsFilter?.value]]
                : [],
              sourceGroups: sourceGroupFilter ? sourceGroupFilter : [],
              sources: sourceFilter || [],
              countries: countriesFilter?.map((country) => country.value),
              audienceRange: audienceFilter,
              isVerified: sourcesTypesFilter?.value === 'is_verified',
              suspendedByPlatform: sourcesSubtypesFilter === 'suspended_by_platform',
              immatureAccounts: sourcesSubtypesFilter === 'immature_account',
              showOnlyBots:
                sourcesTypesFilter?.value === 'is_bot' &&
                sourcesTypesFilter?.bool,
              discreditations:
                sourcesTypesFilter?.value === 'discreditated' &&
                !sourcesSubtypesFilter
                  ? [
                      'SANCTIONS',
                      'DISINFORMATION',
                      'HACKED_OR_STOLEN',
                      'INVOLVED_IN_INFLUENCE_OPERATIONS',
                    ]
                  : sourcesTypesFilter?.value === 'discreditated' &&
                    sourcesSubtypesFilter
                  ? [sourcesSubtypesFilter]
                  : [],
              affiliationCountries:
                sourcesTypesFilter?.value === 'state_affiliated' &&
                affiliationCountryId
                  ? [{value: affiliationCountryId}]
                  : sourcesTypesFilter?.value === 'state_affiliated' &&
                    !affiliationCountryId
                  ? [{value: 'all'}]
                  : [],
            });
            closeModal()
          }}
        />
      ) : (
        ''
      )}

      {addToCaseModal.isOpen ? (
        <AddToNarrativeModal
          isOpen={addToCaseModal.isOpen}
          deduplication={false}
          caseId={narrative.id}
          areAllMessagesSelected={true}
          platforms={addToCaseModal.platforms}
          sourceGroups={addToCaseModal.sourceGroups}
          sources={addToCaseModal.sources}
          countries={addToCaseModal.countries}
          showOnlyBots={addToCaseModal.showOnlyBots}
          discreditations={addToCaseModal.discreditations}
          affiliationCountries={addToCaseModal.affiliationCountries}
          audienceRange={addToCaseModal.audienceRange}
          isVerified={addToCaseModal.isVerified}
          suspendedByPlatform={addToCaseModal.suspendedByPlatform}
          immatureAccount={addToCaseModal.immatureAccounts}
          onChange={(bool) => {
            setAddToCaseModal({
              isOpen: bool,
              platforms: null,
              sourceGroups: null,
              sources: null,
              countries: null,
              showOnlyBots: false,
              discreditations: null,
              audienceRange: null,
              affiliationCountries: null,
              isVerified: false,
              suspendedByPlatform: false,
              immatureAccounts: false,
            });
          }}
        />
      ) : (
        ''
      )}
    {addToGroupModal.isOpen ? (
      <AddToSourceGroupModal
        isOpen={addToGroupModal.isOpen}
        deduplication={false}
        areAllMessagesSelected={true}
        platforms={addToGroupModal.platforms}
        sourceGroups={addToGroupModal.sourceGroups}
        sourcesFilter={addToGroupModal.sources}
        countries={addToGroupModal.countries}
        showOnlyBots={addToGroupModal.showOnlyBots}
        discreditations={addToGroupModal.discreditations}
        affiliationCountries={addToGroupModal.affiliationCountries}
        audienceRange={addToGroupModal.audienceRange}
        isVerified={addToGroupModal.isVerified}
        suspendedByPlatform={addToGroupModal.suspendedByPlatform}
        immatureAccount={addToGroupModal.immatureAccounts}
        narrative={narrative}
        onChange={(bool) => {
          setAddToGroupModal({
            isOpen: bool,
            platforms: null,
            sourceGroups: null,
            sources: null,
            countries: null,
            showOnlyBots: false,
            discreditations: null,
            affiliationCountries: null,
            audienceRange: null,
            isVerified: false,
            suspendedByPlatform: false,
            immatureAccounts: false,
          });
        }}
      />
    ) : (
      ''
    )}
    </div>
  );
}

