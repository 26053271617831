import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../../API';
import styles from './UserSettingsPage.module.scss';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { LoaderSmall } from '../../components/LoaderSmall/LoaderSmall';
import { QuotaProgressBar } from './QuotaProgressBar';
import { format} from "date-fns";


export const BillingAndSubscriptions = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();

  const [currentUser, setCurrentUser] = useCurrentUser();
  const currentWorkspace = currentUser?.workspace;
  const workspaceConfig = currentWorkspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showBilling = featureFlags?.includes('enable_billing');

  const [quota, setQuota] = useState(null);

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  const getWorkspaceQuota = useCallback(() => {
    API.fetch('GET', `/API/v1/workspaces/current/quota`).then(
      (data) => {
        setQuota(data);
      },
    );
  }, [currentWorkspace]);

  useEffect(() => {
    getWorkspaceQuota();
  }, [getWorkspaceQuota]);

  const subscriptionsMapList = {
    'TRIAL': {
      name: 'Trial',
      description: 'You are currently using a Free Trial. A trial provides temporary access to the full platform functionality, allowing you to evaluate all Osavul features.'
    },
    'FREE_ACCESS': {
      name: 'Free',
      description: 'You are currently using a Free Access plan. Available for our partners, this plan includes a limited access for analytical module.'
    },
    'PAID_CLIENT': {
      name: 'Paid',
      description: 'You are currently using a Paid Subscription. The paid subscription provides a monthly quota for the Analytical Module and AI Module, defining the maximum amount of data that can be analyzed through the platform.'
    },
    'INTERNAL': {
      name: 'Internal',
      description: 'You are currently using an Internal plan. The internal plan is used for the team’s internal projects and is subject to limitations based on the specific project it is created for.'
    },
    'PAID_CLIENT_UCBI': {
      name: 'UCBI',
      description: 'Your subscription has been paid by a partner organization. The paid subscription provides a monthly quota for the Analytical Module and AI Module, defining the maximum amount of data that can be analyzed through the platform.'
    },
    'PAID_CLIENT_I3': {
      name: 'I3',
      description: 'Your subscription has been paid by a partner organization. The paid subscription provides a monthly quota for the Analytical Module and AI Module, defining the maximum amount of data that can be analyzed through the platform.'
    },
    'DEACTIVATED': {
      name: 'Deactivated',
      description: 'The workspace is not active and can not use any of the platform features. To reactivate your account, please, contact sales.'
    },
  };

  const quotaMapList = {
    'ANALYTICAL': {
      name: 'Analytical Quota',
      description: 'Is used when you search for messages or create cases. Each message in your search results / case takes 1 credit from this quota.'
    },
    'AI': {
      name: 'AI Quota',
      description: 'Is used when you apply AI analysis to your cases. Each message used for Contextual sentiment analysis or Topic modeling takes 1 credit from this quota.'
    }
  };

  const calculateTrialDaysLeft = () => {
    const endDate = new Date(currentWorkspace?.trial_end_date);
    const currentDate = new Date();
    const diffTime = endDate - currentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  return (
    <div>
      <h3 id='billing'>{t('Billing and Subscriptions')}</h3>
      <div className={styles.cardWrapper}>
        <div className={styles.cardHeader}>
          <h5>{t('Plan details')}</h5>
          <p className={styles.workspaceStatus}>{t(subscriptionsMapList[currentWorkspace?.status]?.name)}</p>
        </div>
    
        <div className={styles.cardContent}>
          <p>{t(subscriptionsMapList[currentWorkspace?.status].description)}</p>
        </div>
        <div className={styles.cardFooter}>
          {currentWorkspace?.status === 'TRIAL' ? (
            <div className={currentWorkspace?.is_trial_ending ? styles.trialInfoWarning : styles.trialInfo }>
              {t('Expires on')}{' '}
              {format(new Date(currentWorkspace?.trial_end_date), "dd-MM-yyyy")} (
              {t('daysLeft', { count: calculateTrialDaysLeft() })})
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      {!showBilling ? '' : quota ? <div className={styles.cardWrapper}>
        <div className={styles.cardHeader}>
          <h4>{t('Plan usage')}</h4>
        </div>
        <div className={styles.cardContent}>
          {Object?.keys(quota?.quota)?.sort((a, b) => a > b ? -1 : 1).map((quotaKey) => (
            <QuotaProgressBar
              key={quotaKey}
              value={quota?.quota[quotaKey]?.balance}
              max={quota?.quota[quotaKey]?.limit}
              label={quotaMapList[quotaKey]?.name}
              tooltip={quotaMapList[quotaKey]?.description}
              resetDate={quota?.quota[quotaKey]?.renewal_date}
            />
          ))}

        </div>
      </div> : <div className={styles.cardWrapper}><LoaderSmall /></div>}
    </div>
  );
};
