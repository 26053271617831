
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import styles from '../UserSettingsPage/UserSettingsPage.module.scss';
export const PopulationProgressBar = ({value, max, label, status, eta = null}) => {
  const { t } = useTranslation();

  const formatSecondsToMinutes = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  let progressBarClass = styles.progressGreen;
  return (
    <div className={styles.statusBar}>
      <div className={styles.statusBarHeader}>
        <h4>{t(label)}</h4>
        <div className={styles.statusBarHeaderRight}>
          <span>
            {value || 0}% {t('of')} {max}% {t('populated')}
          </span>
        </div>
      </div>

      {status !== 'FAILED' ? (
        <div className={styles.quotaBar}>
          <progress className={`${progressBarClass}`} value={value} max={max}>
            {value}
          </progress>
          <div className={styles.quotaBarLegend}>
            {eta ? (
              <span>
                {t('ETA')} {formatSecondsToMinutes(eta)}
              </span>
            ) : (
              <span>{t('ETA is being calculated')}</span>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.quotaBar}>
          <div className={styles.quotaBarLegend}>
            <div className={styles.quotaBarFail}>{t('Case population has failed. Contact your workspace admin to retry.')}</div>
          </div>
        </div>
      )}
    </div>
  );
}
