
export const IoCTypeOptionsList = (t) => {
  return [
  {label: t('Sanctions'), value: 'SANCTIONS'},
  {label: t('Information manipulation'), value: 'DISINFORMATION'},
  {label: t('Hackers / Hacktivists'), value: 'HACKED_OR_STOLEN'},
  // {label: t('Influence operations'), value: 'INVOLVED_IN_INFLUENCE_OPERATIONS'},
]}

export const mapIoCTypeOptions = (t) => {
  return {
  'SANCTIONS': {label: t('Sanctions'), value: 'SANCTIONS'},
  'DISINFORMATION': {label: t('Information manipulation'), value: 'DISINFORMATION'},
  'HACKED_OR_STOLEN': {label: t('Hackers / Hacktivists'), value: 'HACKED_OR_STOLEN'},
  'INVOLVED_IN_INFLUENCE_OPERATIONS': {label: t('Influence operations'), value: 'INVOLVED_IN_INFLUENCE_OPERATIONS'},
  }
}

export const mapCompromisedEntityTypes = (t) => {
  return {
    INVOLVED_IN_INFLUENCE_OPERATIONS: {title: t('Influence operations'), tooltip: t('Account has been involved in influence operations')},
    HACKED_OR_STOLEN: {title: t('Hackers / Hacktivists'), tooltip: t('Engaged or claimed to engage in cyber attacks or similar activity')},
    SANCTIONS: {title: t('Sanctions'), tooltip: t('Sources are listed in official sanctions list of EU (and member countries), US, UK, and other countries')},
    DISINFORMATION: {title: t('Information manipulation'), tooltip: t('Sources are frequently sharing manipulative content, which was autodetected by Osavul AI')}
  }
}

export const mapBotEntityTypes = (t) => {
  return {
    'suspended_by_platform': {
    title: t('Suspended by platform'),
    tooltip: t('Account has been suspended by a platform')
    },
    'inauthentic_behavior': {
      title: t('Inauthentic behavior'),
      tooltip: t('Account was engaged in an inauthentic behavior'),
    },
    'immature_account': {
      title: t('Immature accounts'),
      tooltip: t('Recently created accounts or accounts with few followers'),
    },
  }
}

export const mapAffiliationEntityTypes = (t) => {
  return {
    STATE_AGENCY: t('State agency'),
    GOVERNMENT_OFFICIAL: t('Government official'),
    STATE_OWNED_MEDIA: t('State owned media'),
    REPORTEDLY_STATE_AFFILIATED: t('Reportedly state affiliated'),
  }
};

export const affiliationTypesOptions = (t) => {
  return [
    { value: 'STATE_AGENCY', label: t('State agency') },
    { value: 'GOVERNMENT_OFFICIAL', label: t('Government official') },
    { value: 'STATE_OWNED_MEDIA', label: t('State owned media') },
    { value: 'REPORTEDLY_STATE_AFFILIATED', label: t('Reportedly state affiliated') },
  ]
}