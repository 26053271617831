import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from 'react';

import { Collapsible } from './Collapsible';
import { ReactComponent as FilterIcon } from '../assets/filter.svg';
import { StatusChooser } from './StatusChooser';
import {useClickOutside} from '../../../utils/useClickOutside.jsx'
import './Filter.scss';
import styles from './Filter.module.scss';
import {TypeChooser} from "./TypeChooser";
import {OutputFormatChooser} from "./OutputFormatChooser";


export const Filter = ({onChange, objectIdValue, typesValue, statusesValue, outputFormatsValue}) => {
  const { t } = useTranslation();
  const clickRef = useRef();
  const [isActive, setIsActive] = useState(false);

  const [objectId, setObjectId] = useState(objectIdValue || null);
  const [types, setTypes] = useState(typesValue || []);
  const [statuses, setStatuses] = useState(statusesValue || []);
  const [outputFormats, setOutputFormats] = useState(outputFormatsValue || []);

  const [isObjectIdOpen, setIsObjectIdOpen] = useState(false);
  const [isTypesOpen, setIsTypesOpen] = useState(false);
  const [isStatusesOpen, setIsStatusesOpen] = useState(false);
  const [isOutputFormatsOpen, setIsOutputFormatsOpen] = useState(false);

  useEffect(() => {
    setObjectId(objectIdValue || null);
    setTypes(typesValue || []);
    setStatuses(statusesValue || []);
    setOutputFormats(outputFormatsValue || []);
  }, [objectIdValue, typesValue, statusesValue, outputFormatsValue]);
  
  let filtersSumm = (objectId ? 1 : 0) + types.length + statuses.length + outputFormats.length;

  useClickOutside(clickRef, () => {
    if(isActive) {
      setIsActive(false)
    }
  })

  return (
    <div ref={clickRef} className={styles.root}>
      <button 
        className={isActive ? `${styles.button} ${styles.filters} ${styles.active}` : `${styles.button} ${styles.filters}`}
        onClick={() => setIsActive(!isActive)}
      >
        {filtersSumm > 0 ? <span className='summ'>{filtersSumm }</span> : ''}
        {t('Filters')}
        <FilterIcon className={isActive ? 'iconActive' : 'icon'}/>
      </button>

      <div className={isActive ? `${styles.container} ${styles.show}` : `${styles.container}`}>
        <div className={styles.header}>
          <span>{t('Filter by')}</span>

          <span onClick={()=> {
            if (!isObjectIdOpen || !isTypesOpen || !isStatusesOpen || !isOutputFormatsOpen) {
              setIsObjectIdOpen(true);
              setIsTypesOpen(true);
              setIsStatusesOpen(true);
              setIsOutputFormatsOpen(true);
            } else {
              setIsObjectIdOpen(false);
              setIsTypesOpen(false);
              setIsStatusesOpen(false);
              setIsOutputFormatsOpen(false);
            }
          }}>
            {isObjectIdOpen && isTypesOpen && isStatusesOpen && isOutputFormatsOpen ? `${t('Minimize all')}` : `${t('Expand all')}`}
          </span>
        </div>

        <div className={styles.collapsiblesWrapper}>

          <Collapsible 
            name={'Object'}
            summ={types.length}
            isOpen={isTypesOpen}
            onChange={(open) => setIsTypesOpen(open)}
          >
            <TypeChooser
              selected={types}
              onChange={(checklist) => setTypes(checklist)}
            />
          </Collapsible>

          <Collapsible
            name={'Object ID'}
            summ={objectId ? 1 : 0}
            isOpen={isObjectIdOpen}
            onChange={(open) => setIsObjectIdOpen(open)}
          >
            <div className={styles.inputsContainer}>
              <div className={styles.inputContainer}>
                <input
                  className={styles.output}
                  type='text'
                  value={objectId}
                  onChange={(e) => setObjectId(e.target.value)}
                />
              </div>
            </div>
          </Collapsible>

          <Collapsible
            name='Status'
            summ={statuses.length}
            isOpen={isStatusesOpen}
            onChange={(open) => setIsStatusesOpen(open)}
          >
            <StatusChooser
              selected={statuses}
              onChange={(checklist) => setStatuses(checklist)}
            />
          </Collapsible>

          <Collapsible 
            name='Output format'
            summ={outputFormats.length}
            isOpen={isOutputFormatsOpen}
            onChange={(open) => setIsOutputFormatsOpen(open)}
          >
            <OutputFormatChooser
              selected={outputFormats}
              onChange={(checklist) => setOutputFormats(checklist)}
            />
          </Collapsible>
        </div>

        <div className={styles.controls}>
          <button 
            className={`${styles.button} ${styles.apply}`}
            onClick={() => {
              onChange(
                objectId,
                types,
                statuses,
                outputFormats,
              )
              setIsActive(false)
            }}
          >
            {t('Apply')}
          </button>

          <button 
            className={`${styles.button} ${styles.reset}`}
            onClick={() => {
              setObjectId(null);
              setTypes([]);
              setStatuses([]);
              setOutputFormats([]);
            }}
          >
            {t('Reset all')}
          </button>
        </div>
      </div>
    </div>
  )
}