import * as d3 from 'd3';
import { Link } from 'react-router-dom';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VerifiedTwitter } from '../assets/verifiedTwitter.svg';
import { platformIconsMapping } from '../../../utils/platforms';
import { cutString } from '../../../utils/cutString';
import {
  mapCompromisedEntityTypes,
  mapBotEntityTypes,
} from '../../../utils/actors';
import { decodeSourceName } from '../../../utils/decodeURI';
import { useCurrentUser } from '../../../contexts/CurrentUser';
import styles from '../SourcePage.module.scss';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');

export const LinkedActorsTable = ({ data, tableId }) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const hideRiskActors = featureFlags?.includes('hide_risk_actors');

  const getBotType = (source) => {
    if (source?.suspended_by_platform === true) {
      return mapBotEntityTypes(t)['suspended_by_platform'];
    } else if (
      source?.inauthentic_behavior === true ||
      source?.is_bot === true
    ) {
      return mapBotEntityTypes(t)['inauthentic_behavior'];
    } else if (source?.immature_account === true) {
      return mapBotEntityTypes(t)['immature_account'];
    } else {
      return null;
    }
  };

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.linkedActors} id={tableId}>
        <thead>
          <tr>
            <td>
              <div className={styles.wrapper}>
                <span>{t('Name')}</span>
              </div>
            </td>
            <td>
              <div className={styles.wrapper}>
                <span>{t('Location')}</span>
              </div>
            </td>
            <td>
              <div className={styles.wrapper}>
                <span>{t('Audience')}</span>
              </div>
            </td>
            <td>
              <div className={styles.wrapper}>
                <span>{t('Links')}</span>
              </div>
            </td>
          </tr>
        </thead>

        <tbody>
          {data?.objects?.map((item) => {
            let followers_cnt = item.audience;
            let botType = getBotType(item);

            return (
              <tr key={item.id}>
                <td>
                  <div className={styles.sourceNameWrapper}>
                    <div className={styles.platformWrapper}>
                      {platformIconsMapping[item.source_type]}
                    </div>
                    <div className={styles.sourceNameContainer}>
                      <span>
                        <Link to={`/sources/${item?.id}`}>
                          {decodeSourceName(item?.name)}
                        </Link>
                        {item?.is_verified ? (
                          <span className={styles.verifiedSource}>
                            <VerifiedTwitter />
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                      <div className={styles.sourceInfo}>
                        <span>#{item.id}</span>
                        <span>&#8226;</span>
                        <a
                          href={item?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {cutString(decodeSourceName(item?.url))}
                        </a>
                      </div>
                      <div className={styles.tagsContainer}>
                        {item?.affiliations?.length > 0 ? (
                          <div className={styles.dualTag}>
                            <div className={styles.tagKey}>
                              {t('State-affiliated')}
                            </div>
                            <div className={styles.tagValue}>
                              {item?.affiliations[0]?.country?.code}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {item?.discreditations?.length > 0 && !hideRiskActors ? (
                          <Tooltip
                            content={
                              mapCompromisedEntityTypes(t)[
                                item?.discreditations[0]?.entity_type
                              ]?.tooltip
                            }
                            position="right"
                          >
                            <div className={styles.tag}>
                              {
                                mapCompromisedEntityTypes(t)[
                                  item?.discreditations[0]?.entity_type
                                ]?.title
                              }
                            </div>
                          </Tooltip>
                        ) : (
                          ''
                        )}

                        {botType === null ? (
                          ''
                        ) : (
                          <div className={styles.tag}>{botType?.title}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {item.origin_country ? item.origin_country.name : t('N/A')}
                </td>
                <td>{formatNumberSignificant(followers_cnt)}</td>
                <td className="message">{formatNumber(item.mentions_count)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
