import { useLocation, useNavigate, NavLink } from 'react-router-dom';

export function ReloadNavLink({ to, children, ...props }) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (location.pathname === to) {
      e.preventDefault();
      navigate(to, { state: { reload: true } });
    }
  };

  return (
    <NavLink to={to} onClick={handleClick} {...props}>
      {children}
    </NavLink>
  );
}
