import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Tooltip from '../../components/Tooltip/Tooltip';

import { ReactComponent as ThreatIcon } from '../../assets/threat.svg';
import { API } from '../../API';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { ReloadNavLink } from '../Layout/ReloadNavLink';

import websocketConnection from '../../websocket';

export const ThreatsLink = ({ itemTextClassname, threatCounterClassname }) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();

  const [newMessages, setNewMessages] = useState(null);

  const isUserUCBIAdmin =
    currentUser.is_super_admin || currentUser?.workspace?.config?.is_ucbi_admin;

  const fetchThreatsInbox = () => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '1');

    let url;

    if (isUserUCBIAdmin) {
      urlParams.set('review_status', 'PENDING');
      url = `/API/v1/threats/moderation/?${urlParams.toString()}`;
    } else {
      urlParams.set('read', false);
      urlParams.set('types', 'INCIDENT');
      url = `/API/v1/threats/inbox/?${urlParams.toString()}`;
    }

    API.fetch('GET', url).then((data) => {
      setNewMessages(data.total);
    });
  };

  useEffect(() => {
    fetchThreatsInbox();
    const handleMessage = (data) => {
      try {
        if (data?.object_type === 'THREAT') {
          fetchThreatsInbox();
        }
      } catch {}
    };

    websocketConnection.addHandler('THREAT', handleMessage);
  }, []);

  return (
    <ReloadNavLink to="/threats">
      <Tooltip
        content={
          currentUser?.workspace?.config?.is_ucbi_admin ||
          currentUser?.workspace?.config?.is_reporter_only_user
            ? t('Instances')
            : t('Threats')
        }
        position="right"
      >
        <div className="iconWrapper">
          <ThreatIcon />
          {newMessages ? (
            <span className="threatCounter">{newMessages}</span>
          ) : (
            ''
          )}
        </div>
      </Tooltip>

      <span className={itemTextClassname}>
        {currentUser?.workspace?.config?.is_ucbi_admin ||
        currentUser?.workspace?.config?.is_reporter_only_user
          ? t('Instances')
          : t('Threats')}
        {newMessages ? (
          <div className={threatCounterClassname}>{newMessages}</div>
        ) : (
          ''
        )}
      </span>
    </ReloadNavLink>
  );
};
