import { useCallback, useEffect } from 'react';
import { API } from '../../API';
import Modal from '../Modal';
import * as d3 from 'd3';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useCurrentQuota } from '../../contexts/CurrentQuota';
import {ReactComponent as InfoIcon} from '../../assets/info.svg';
import { Tooltip } from 'react-tooltip';

import styles from './QuotaCheckModal.module.scss';

const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

export const QuotaCheckModal = ({
  isOpen,
  data,
  onClose,
  onChange,
  buttonText,
  redirect=true
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentQuota, setCurrentQuota] = useCurrentQuota();

  const getWorkspaceQuota = useCallback(() => {
    API.fetch('GET', `/API/v1/workspaces/current/quota`).then((data) => {
      setCurrentQuota(data.quota);
    });
  }, []);
  
  useEffect(() => {
    getWorkspaceQuota();
  }, [getWorkspaceQuota]);

  const isHighAnalyticalQuotaUsage = data?.is_significant_analytical_spend && currentQuota?.ANALYTICAL.balance !== 0;
  const isHiqhAIQuotaUsage = data?.is_significant_ai_spend && currentQuota?.AI.balance !== 0;
  const isAnalyticalQuotaInsufficient = !data?.can_create && (data?.analytical_quota_usage > currentQuota?.ANALYTICAL?.balance);
  const isAIQuotaInsufficient = !data?.can_create && (data?.ai_quota_usage > currentQuota?.AI?.balance);

  let analyticalQuotaUsageClass = styles.green;
  let aiQuotaUsageClass = styles.green;
  
  if (data?.is_significant_analytical_spend) {
    analyticalQuotaUsageClass = styles.yellow;
  }

  if (data?.is_significant_ai_spend) {
    aiQuotaUsageClass = styles.yellow;
  }

  if (isAnalyticalQuotaInsufficient) {
    analyticalQuotaUsageClass = styles.red;
  }

  if (isAIQuotaInsufficient) {
    aiQuotaUsageClass = styles.red;
  }


  return (
    <Modal
      isVisible={isOpen}
      title={t('Quota validation')}
      className={styles.quotaCheckingModal}
      content={
        <div>
          <div className={styles.quotaCheckModal}>
            <div className={styles.quotaInfoContainer}>
              <h4>{t('Expected quota usage by the new case')}</h4>
              <div className={styles.quotaInfo}>
                <div className={styles.quotaInfo_title}>
                  {t('Analytical quota usage')}:{' '}
                </div>
                <div className={styles.quotaInfo_content}>
                  <span>
                    <span className={analyticalQuotaUsageClass}>
                      {formatNumberSignificant(data?.analytical_quota_usage) ??
                        0}
                    </span>{' '}
                    /{' '}
                    {formatWithCustomGigaBillion(
                      currentQuota?.ANALYTICAL?.balance,
                    ) ?? 0}
                  </span>
                  {isHighAnalyticalQuotaUsage && !isAnalyticalQuotaInsufficient ? (
                    <span className={styles.quotaInfo_warning}>
                      <div className={styles.quotaInfo_warning_title}>
                        {t('High usage')}
                        <div
                          className={styles.quotaInfo_warning_icon}
                          data-tooltip-id="analyticalQuotaInfoTooltip"
                          data-tooltip-content={t(
                            `You are about to make an action that will use more than 80% of your plan’s remaining quota.`,
                          )}
                        >
                          <InfoIcon />
                        </div>
                      </div>
                    </span>
                  ) : (
                    ''
                  )}

                  {isAnalyticalQuotaInsufficient ? (
                    <span className={styles.quotaInfo_warning}>
                      <div className={styles.quotaInfo_warning_title}>
                        {t('Insufficient quota')}
                        <div
                          className={styles.quotaInfo_warning_icon}
                          data-tooltip-id="insufficientQuotaInfoTooltip"
                          data-tooltip-content={currentQuota?.ANALYTICAL?.balance === 0 ? t(
                            `Your workspace has reached its Analytical quota limit. Contact your manager to request more quota or wait till the next billing month.`,
                          ) : t('There is not enough Analytical quota to create a new case. Refine your search to use less quota or contact your manager to request more quota.')}
                        >
                          <InfoIcon />
                        </div>
                      </div>
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className={styles.quotaInfo}>
                <div className={styles.quotaInfo_title}>
                  {t('AI quota usage')}:{' '}
                </div>
                <div className={styles.quotaInfo_content}>
                  <span>
                    <span className={aiQuotaUsageClass}>
                      {formatNumberSignificant(data?.ai_quota_usage) ?? 0}
                    </span>{' '}
                    /{' '}
                    {formatWithCustomGigaBillion(currentQuota?.AI?.balance) ??
                      0}
                  </span>
                  {isHiqhAIQuotaUsage && !isAIQuotaInsufficient ? (
                    <span className={styles.quotaInfo_warning}>
                      <div className={styles.quotaInfo_warning_title}>
                        {t('High usage')}
                        <div
                          className={styles.quotaInfo_warning_icon}
                          data-tooltip-id="AIQuotaInfoTooltip"
                          data-tooltip-content={t(
                            `You are about to make an action that will use more than 80% of your plan’s remaining quota.`,
                          )}
                        >
                          <InfoIcon />
                        </div>
                      </div>
                    </span>
                  ) : (
                    ''
                  )}
                  {isAIQuotaInsufficient ? (
                    <span className={styles.quotaInfo_warning}>
                      <div className={styles.quotaInfo_warning_title}>
                        {t('Insufficient quota')}
                        <div
                          className={styles.quotaInfo_warning_icon}
                          data-tooltip-id="insufficientQuotaInfoTooltip"
                          data-tooltip-content={currentQuota?.AI?.balance === 0 ? t(
                            `Your workspace has reached its AI quota limit. Contact your manager to request more quota or wait till the next billing month.`,
                          ) : t('There is not enough AI quota to create a new case. Refine your search to use less quota or contact your manager to request more quota.')}
                        >
                          <InfoIcon />
                        </div>
                      </div>
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            
            {data.can_create ? (
              <>
                <div className={styles.quotaInfoMessage}>
                  <div className={styles.quotaInfoMessage_icon}>
                    <InfoIcon />
                  </div>
                  <span>{t('The selected timeframe for the case is not finished yet. New data will be added to the case during the updates. This will increase quota usage.')}</span>
                </div>
                <p>{t('Would you like to continue?')}</p>
              </>
            ) : (
              <>
                {redirect && !data?.can_create && currentQuota?.ANALYTICAL?.balance === 0 ? <p>
                  {t(
                    'After closing this message, you will be redirected to the Cases page.',
                  )}
                </p> : ''}
                <p>
                  {/* DO NOT MOVE LINK TAG TO ANOTHER LINE!!! The whole sentence should be a single-line */}
                  <Trans i18nKey="redirectToSettings"> 
                    You can check your workspace quota on the user settings <a rel='noreferrer' target="_blank" href='/users/settings#billing'>page</a>.
                  </Trans>
                </p>
              </>
            )}
            <Tooltip
              id="analyticalQuotaInfoTooltip"
              place="bottom"
              style={{ width: '550px' }}
            />
            <Tooltip
              id="AIQuotaInfoTooltip"
              place="bottom"
              style={{ width: '550px' }}
            />

            <Tooltip
              id="insufficientQuotaInfoTooltip"
              place="bottom"
              style={{ width: '550px' }}
            />
          </div>
        </div>
      }
      footer={
        data?.can_create ? (
          <>
            <button
              className="btn-primary"
              onClick={(e) => {
                onChange(e);
                onClose();
              }}
            >
              {buttonText ? buttonText : t('Save')}
            </button>
            <button
              className="btn-reset"
              onClick={() => {
                onClose();
              }}
            >
              {t('Cancel')}
            </button>
          </>
        ) : (
          <>
            <button
              className="btn-reset"
              onClick={() => {
                onClose();
                if (redirect && !data?.can_create && currentQuota?.ANALYTICAL?.balance === 0) {
                  navigate('/narratives')
                }
              }}
            >
              {t('Close')}
            </button>
          </>
        )
      }
      onClose={() => {
        onClose();
        if (redirect && !data?.can_create && currentQuota?.ANALYTICAL?.balance === 0) {
          navigate('/narratives')
        }
      }}
    />
  );
};
