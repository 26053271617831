import React from 'react';
import { noop } from '../utils/common';

export interface Quota {
  AI: {
    limit: number;
    balance: number;
    is_low: boolean;
    renewal_date: string;
  };
  ANALYTICAL: {
    limit: number;
    balance: number;
    is_low: boolean;
    renewal_date: string;
  };
}

export const CurrentQuotaContext = React.createContext<
  [Quota | null, (currentQuota: Quota) => void]
>([null, noop]);

export const useCurrentQuota = () => React.useContext(CurrentQuotaContext);

export const CurrentQuotaProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const value = React.useState<Quota | null>(null);

  return (
    <CurrentQuotaContext.Provider value={value}>
      {children}
    </CurrentQuotaContext.Provider>
  );
};
