import { filter } from "d3";
import { id } from "date-fns/locale";

export const languageNamesMapping = {
  UK: 'Ukrainian',
  RU: 'Russian',
  EN: 'English',
  FR: 'French',
  PL: 'Polish',
  ES: 'Spanish',
  PT: 'Portuguese',
  DE: 'German',
  HU: 'Hungarian',
  AR: 'Arabic',
  FA: 'Persian',
  NO: 'Norwegian',
  SV: 'Swedish',
  RO: 'Romanian',
  FI: 'Finnish',
  ET: 'Estonian',
  LV: 'Latvian',
  LT: 'Lithuanian',
  BG: 'Bulgarian',
  CS: 'Czech',
  EL: 'Greek',
  NL: 'Dutch',
  HY: 'Armenian',
  ID: 'Indonesian',
  KK: 'Kazakh',
  IT: 'Italian',
  HI: 'Hindi',
  TH: 'Thai',
  TR: 'Turkish',
  DA: 'Danish',
  BN: 'Bengali',
  CKB: 'Kurdish',
  BE: 'Belarusian',
  IS_: 'Icelandic',
}

export const languageNamesMappingUkrainian = {
  UK: 'Українська',
  RU: 'Російська',
  EN: 'Англійська',
  FR: 'Французька',
  PL: 'Польська',
  ES: 'Іспанська',
  PT: 'Португальська',
  DE: 'Німецька',
  HU: 'Угорська',
  AR: 'Арабська',
  FA: 'Перська',
  NO: 'Норвезька',
  SV: 'Шведська',
  RO: 'Румунська',
  FI: 'Фінська',
  ET: 'Естонська',
  LV: 'Латвійська',
  LT: 'Литовська',
  BG: 'Болгарська',
  CS: 'Чеська',
  EL: 'Грецька',
  NL: 'Голландська',
  HY: 'Вірменська',
  ID: 'Індонезійська',
  KK: 'Казахська',
  IT: 'Італійська',
  HI: 'Гінді',
  TH: 'Тайська',
  TR: 'Турецька',
  DA: 'Данська',
  BN: 'Бенгальська',
  CKB: 'Kурдська',
  BE: 'Білоруська',
  IS_: 'Ісландська',
}

export const languageMapping = {
  'UK': 1,
  'EN': 2,
  'RU': 3,
  'FR': 4,
  'PL': 5,
  'ES': 6,
  'PT': 7,
  'DE': 8,
  'HU': 9,
  'AR': 10,
  'FA': 11,
  'NO': 12,
  'SV': 13,
  'RO': 14,
  'FI': 15,
  'ET': 16,
  'LV': 17,
  'LT': 18,
  'BG': 19,
  'CS': 20,
  'EL': 21,
  'NL': 22,
  'HY': 23,
  'ID': 24,
  'KK': 25,
  'IT': 26,
  'HI': 27,
  'TH': 28,
  'TR': 29,
  'DA': 30,
  'BN': 31,
  'CKB': 32,
  'BE': 33,
  'IS_': 34,
}


export const languageNumberMapping = {
    1: 'UK',
    2: 'EN',
    3: 'RU',
    4: 'FR',
    5: 'PL',
    6: 'ES',
    7: 'PT',
    8: 'DE',
    9: 'HU',
    10: 'AR',
    11: 'FA',
    12: 'NO',
    13: 'SV',
    14: 'RO',
    15: 'FI',
    16: 'ET',
    17: 'LV',
    18: 'LT',
    19: 'BG',
    20: 'CS',
    21: 'EL',
    22: 'NL',
    23: 'HY',
    24: 'ID',
    25: 'KK',
    26: 'IT',
    27: 'HI',
    28: 'TH',
    29: 'TR',
    30: 'DA',
    31: 'BN',
    32: 'CKB',
    33: 'BE',
    34: 'IS_',
}

export const languageOptions = [
  { value: 'UK', label: languageNamesMapping['UK'], id: 1},
  { value: 'RU', label: languageNamesMapping['RU'], id: 3},
  { value: 'EN', label: languageNamesMapping['EN'], id: 2},
  { value: 'FR', label: languageNamesMapping['FR'], id: 4},
  { value: 'PL', label: languageNamesMapping['PL'], id: 5},
  { value: 'ES', label: languageNamesMapping['ES'], id: 6},
  { value: 'PT', label: languageNamesMapping['PT'], id: 7},
  { value: 'DE', label: languageNamesMapping['DE'], id: 8},
  { value: 'HU', label: languageNamesMapping['HU'], id: 9},
  { value: 'AR', label: languageNamesMapping['AR'], id: 10},
  { value: 'FA', label: languageNamesMapping['FA'], id: 11},
  { value: 'NO', label: languageNamesMapping['NO'], id: 12},
  { value: 'SV', label: languageNamesMapping['SV'], id: 13},
  { value: 'RO', label: languageNamesMapping['RO'], id: 14},
  { value: 'FI', label: languageNamesMapping['FI'], id: 15},
  { value: 'ET', label: languageNamesMapping['ET'], id: 16},
  { value: 'LV', label: languageNamesMapping['LV'], id: 17},
  { value: 'LT', label: languageNamesMapping['LT'], id: 18},
  { value: 'BG', label: languageNamesMapping['BG'], id: 19},
  { value: 'CS', label: languageNamesMapping['CS'], id: 20},
  { value: 'EL', label: languageNamesMapping['EL'], id: 21},
  { value: 'NL', label: languageNamesMapping['NL'], id: 22},
  { value: 'HY', label: languageNamesMapping['HY'], id: 23},
  { value: 'ID', label: languageNamesMapping['ID'], id: 24},
  { value: 'KK', label: languageNamesMapping['KK'], id: 25},
  { value: 'IT', label: languageNamesMapping['IT'], id: 26},
  { value: 'HI', label: languageNamesMapping['HI'], id: 27 },
  { value: 'TH', label: languageNamesMapping['TH'], id: 28},
  { value: 'TR', label: languageNamesMapping['TR'], id: 29},
  { value: 'DA', label: languageNamesMapping['DA'], id: 30},
  { value: 'BN', label: languageNamesMapping['BN'], id: 31},
  { value: 'CKB', label: languageNamesMapping['CKB'], id: 32},
  { value: 'BE', label: languageNamesMapping['BE'], id: 33},
  { value: 'IS_', label: languageNamesMapping['IS_'], id: 34},
];

export const languageOptionsUkrainian = [
  { value: 'UK', label: languageNamesMappingUkrainian['UK'], id: 1},
  { value: 'RU', label: languageNamesMappingUkrainian['RU'], id: 3},
  { value: 'EN', label: languageNamesMappingUkrainian['EN'], id: 2},
  { value: 'FR', label: languageNamesMappingUkrainian['FR'], id: 4},
  { value: 'PL', label: languageNamesMappingUkrainian['PL'], id: 5},
  { value: 'ES', label: languageNamesMappingUkrainian['ES'], id: 6},
  { value: 'PT', label: languageNamesMappingUkrainian['PT'], id: 7},
  { value: 'DE', label: languageNamesMappingUkrainian['DE'], id: 8},
  { value: 'HU', label: languageNamesMappingUkrainian['HU'], id: 9},
  { value: 'AR', label: languageNamesMappingUkrainian['AR'], id: 10},
  { value: 'FA', label: languageNamesMappingUkrainian['FA'], id: 11},
  { value: 'NO', label: languageNamesMappingUkrainian['NO'], id: 12},
  { value: 'SV', label: languageNamesMappingUkrainian['SV'], id: 13},
  { value: 'RO', label: languageNamesMappingUkrainian['RO'], id: 14},
  { value: 'FI', label: languageNamesMappingUkrainian['FI'], id: 15},
  { value: 'ET', label: languageNamesMappingUkrainian['ET'], id: 16},
  { value: 'LV', label: languageNamesMappingUkrainian['LV'], id: 17},
  { value: 'LT', label: languageNamesMappingUkrainian['LT'], id: 18},
  { value: 'BG', label: languageNamesMappingUkrainian['BG'], id: 19},
  { value: 'CS', label: languageNamesMappingUkrainian['CS'], id: 20},
  { value: 'EL', label: languageNamesMappingUkrainian['EL'], id: 21},
  { value: 'NL', label: languageNamesMappingUkrainian['NL'], id: 22},
  { value: 'HY', label: languageNamesMappingUkrainian['HY'], id: 23},
  { value: 'ID', label: languageNamesMappingUkrainian['ID'], id: 24},
  { value: 'KK', label: languageNamesMappingUkrainian['KK'], id: 25},
  { value: 'IT', label: languageNamesMappingUkrainian['IT'], id: 26},
  { value: 'HI', label: languageNamesMappingUkrainian['HI'], id: 27},
  { value: 'TH', label: languageNamesMappingUkrainian['TH'], id: 28},
  { value: 'TR', label: languageNamesMappingUkrainian['TR'], id: 29},
  { value: 'DA', label: languageNamesMappingUkrainian['DA'], id: 30},
  { value: 'BN', label: languageNamesMappingUkrainian['BN'], id: 31},
  { value: 'CKB', label: languageNamesMappingUkrainian['CKB'], id: 32},
  { value: 'BE', label: languageNamesMappingUkrainian['BE'], id: 33},
  { value: 'IS_', label: languageNamesMappingUkrainian['IS_'], id: 34},
];
export const keywordsNativeLanguageOptions = [
  { value: '', label: 'Detect Automatically' },
  ...languageOptions,
]

export const filterLanguages = (inputValue, lang) => {
  if (lang === 'uk') {
    return languageOptionsUkrainian.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  } else {
    return languageOptions.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  }
}
