import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useMemo } from 'react';
import Async from 'react-select/async';

import { API } from '../../../API';
import './Filter.scss';

export const FeedsChooser = ({onChange, selected=[], onClose}) => {
  const { t } = useTranslation();

  const [feeds, setFeeds] = useState(null);
  const [selectedFeeds, setSelectedFeeds] = useState([]);

  const page = 1;
  const pageSize = 30;

  const getSelectedFeeds = (selected) => {
    if (selected.length === 0) {
      setSelectedFeeds([])
      return
    }

    const urlParams = new URLSearchParams();

    selected.forEach(id => {
      urlParams.append('ids', id)
    });

    return API.fetch('GET', `/API/v1/feeds?${urlParams.toString()}`).then(data => {
      setSelectedFeeds(data.objects
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((feed) => {
          return {
            value: feed.id,
            label: feed.name,
          };
        }))
    })
  }

  useMemo(() => {
    getSelectedFeeds(selected)
  }, [selected])

  const fetchFeedsOptions = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', pageSize);
    urlParams.set('page', page);

    API.fetch('GET', `/API/v1/feeds?${urlParams.toString()}`).then(
      (data) => {
        setFeeds(data.objects.map((feed) => {
          return {value: feed.id, label: feed.name};
        }));
      },
    );
  }, []);

  useEffect(() => {
    fetchFeedsOptions()
  }, [])

  const getFeeds = (inputValue, callback) => {
    const urlParams = new URLSearchParams();
    urlParams.set('q', inputValue);

    return API.fetch('GET', `/API/v1/feeds?${urlParams.toString()}`).then((data) => {
      callback(
        data.objects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((feed) => {
            return {value: feed.id, label: feed.name};
          })
      );
    });
  };

  let feedsPromiseTimeout;
  const feedsPromiseOptions = function (inputValue) {
    if (feedsPromiseTimeout !== null) {
      clearTimeout(feedsPromiseTimeout);
    }

    if (! inputValue) {
        return [];
    }

    return new Promise((resolve) => {
      feedsPromiseTimeout = setTimeout(() => {
        getFeeds(inputValue, resolve);
      }, 1000);
    });
  }

  if (!feeds) return

  return (
    <div className='chooser feed-search-filter'>
      <Async
        className="select-filter"
        classNamePrefix="select-filter"
        cacheOptions
        defaultOptions={feeds}
        isMulti={true}
        loadOptions={feedsPromiseOptions}
        placeholder={t('Please enter at least 1 symbol')}
        noOptionsMessage={()=> t('Please enter at least 1 symbol')}
        loadingMessage={() => t('Loading...')}
        name="feeds"
        value={selectedFeeds}
        isLoading={selected.length > 0 && selectedFeeds.length === 0}
        onChange={(values) => onChange(values.map((feed) => feed.value))}
        onMenuClose={() => onClose()}
      />
    </div>
  )
}
