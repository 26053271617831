import { useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { ampli } from '../../ampli';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { WidgetContentModal } from '../WidgetContentModal/WidgetContentModal';
import { Tooltip } from 'react-tooltip';
import { AddToNarrativeModal } from '../AddToNarrativeModal/AddToNarrativeModal';
import { AddToSourceGroupModal } from '../AddToSourceGroupModal/AddToSourceGroupModal';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

export const SentimentBar = ({
  data,
  tabList = ['messages', 'views', 'likes'],
  entity,
  entityType,
  sourceGroups,
  countries,
  startDate,
  endDate,
  report,
}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [sentimentTabs, setSentimentTabs] = useState(tabList);
  const [sentimentBar, setSentimentBar] = useState(sentimentTabs[0]);
  const [modal, setModal] = useState({ isVisible: false, sentiment: null, contentType: null });
  const [addToCaseModal, setAddToCaseModal] = useState({isOpen: false, contentTypes: null, sentiment: null, });
  const [addToGroupModal, setAddToGroupModal] = useState({isOpen: false, contentTypes: null, sentiment: null, });

  const sentimentTabsMap = {
    messages: 'Messages',
    views: 'Views',
    likes: 'Reactions',
    reactions: 'Reactions',
    posts: 'Posts',
    comments: 'Comments',
  };

  const contentTypesMap = {
    posts: 'POST',
    comments: 'COMMENT',
    reactions: 'REACTION',
    messages: null,
    articles: 'ARTICLE',
    ads: 'AD',
    videos: 'VIDEO',
    reposts: 'REPOST',
  };


  useEffect(() => {
    setSentimentBar(sentimentTabs[0]);
  }, [sentimentTabs]);

  useEffect(() => {
    if(entityType === 'case' && entity?.type === 'IMPACT_ASSESSMENT') {
      setSentimentTabs(['messages', 'likes']);
    }
  
    if(entityType === 'story') {
      sentimentTabsMap['messages'] = 'All';
    }
  }, []);

  if (
    !['IMPACT_ASSESSMENT', 'MANUAL'].includes(entity?.type) &&
    entity?.parameters?.aspect_based_sentiment &&
    entity?.population_status &&
    entity?.population_status?.aspect_based_sentiment_status === 'POPULATING'
  ) {
    return (
      <div className="report-section">
        <h3 className="trend-chart-controlls">
          <div className="trend-chart-controlls__tabs">
            {t('Sentiments')}
            {sentimentTabs.map((tab) => {
              return (
                <button
                  type="button"
                  key={tab}
                  className={sentimentBar === tab ? 'active' : ''}
                  onClick={() => {
                    ampli.track({
                      event_type: 'Change metric in sentiment chart',
                      event_properties: {
                        user_id: currentUser?.id,
                        workspace_id: currentUser?.workspace_id,
                        entity_id: entity?.id,
                        entity_type: entityType,
                        metric: sentimentBar,
                      },
                    });
                    setSentimentBar(tab);
                  }}
                >
                  <span>{t(sentimentTabsMap[tab])}</span>
                </button>
              );
            })}
          </div>
        </h3>
        <div className="card">
          <div className="card-body">
            <div className="sentiment-bar-message">
              <span className="sentiment-bar-message_text">
                {t(
                  'Please, wait till our AI counts sentiments for this case. It could take several minutes.',
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (
    !['IMPACT_ASSESSMENT', 'MANUAL'].includes(entity?.type) &&
    entity?.parameters?.aspect_based_sentiment &&
    entity?.population_status &&
    entity?.population_status?.aspect_based_sentiment_status === 'FAILED'
  ) {
    return (
      <div className="report-section">
        <h3 className="trend-chart-controlls">
          <div className="trend-chart-controlls__tabs">
            {t('Sentiments')}
            {sentimentTabs.map((tab) => {
              return (
                <button
                  type="button"
                  key={tab}
                  className={sentimentBar === tab ? 'active' : ''}
                  onClick={() => {
                    ampli.track({
                      event_type: 'Change metric in sentiment chart',
                      event_properties: {
                        user_id: currentUser?.id,
                        workspace_id: currentUser?.workspace_id,
                        entity_id: entity?.id,
                        entity_type: entityType,
                        metric: sentimentBar,
                      },
                    });
                    setSentimentBar(tab);
                  }}
                >
                  <span>{t(sentimentTabsMap[tab])}</span>
                </button>
              );
            })}
          </div>
        </h3>
        <div className="card">
          <div className="card-body">
            <div className="sentiment-bar-message">
              <span className="sentiment-bar-message_text_failed">
                {t(
                  'Sentiments calculation has failed. Contact your workspace admin to retry.',
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="report-section">
      <h3 className="trend-chart-controlls">
        <div className="trend-chart-controlls__tabs">
          {t('Sentiments')}
          {sentimentTabs.map((tab) => {
            return (
              <button
                type="button"
                key={tab}
                className={sentimentBar === tab ? 'active' : ''}
                onClick={() => {
                  ampli.track({
                    event_type: 'Change metric in sentiment chart',
                    event_properties: {
                      user_id: currentUser?.id,
                      workspace_id: currentUser?.workspace_id,
                      entity_id: entity?.id,
                      entity_type: entityType,
                      metric: sentimentBar,
                    },
                  });
                  setSentimentBar(tab);
                }}
              >
                <span>{t(sentimentTabsMap[tab])}</span>
              </button>
            );
          })}
        </div>

        {entity?.sentiment_sampling_rate ? (
          <div
            className="sampling"
            data-tooltip-id="sampling-tooltip"
            data-tooltip-content={t(
              'We used sampling to analyze this data. A sample is a statistically significant representation of the dataset, suitable for AI analysis. The sampling rate indicates the part of the total dataset included in the analysis.',
            )}
            data-tooltip-place="bottom-start"
          >
            {t('Sampling')}:
            <span className="sampling__value">
              {' '}
              {entity?.sentiment_sampling_rate.toPrecision(4)}%
            </span>
            <Tooltip id="sampling-tooltip" style={{ width: '200px' }} />
          </div>
        ) : (
          ''
        )}
      </h3>

      {data ? (
        <div className="card">
          <div className="card-body">
            <div className="sentiment-bar-container">
              {data?.positive[sentimentBar] === 0 ? (
                ''
              ) : (
                <span
                  className={
                    entityType === 'story' || entityType === 'case'
                      ? 'sentiment-bar-positive clickable'
                      : 'sentiment-bar-positive'
                  }
                  style={{
                    width: `${data?.positive[sentimentBar + '_share']}%`,
                  }}
                  onClick={() => {
                    if (entityType === 'story' || entityType === 'case') {
                      setModal({
                        isVisible: true,
                        sentiment: 'POSITIVE',
                        contentType:
                          entityType === 'case'
                            ? null
                            : contentTypesMap[sentimentBar],
                      });
                    }
                  }}
                >
                  {formatNumberSignificant(data?.positive[sentimentBar])}
                </span>
              )}
              {data?.neutral[sentimentBar] === 0 ? (
                ''
              ) : (
                <span
                  className={
                    entityType === 'case' || entityType === 'story'
                      ? 'sentiment-bar-neutral clickable'
                      : 'sentiment-bar-neutral'
                  }
                  style={{
                    width: `${data?.neutral[sentimentBar + '_share']}%`,
                  }}
                  onClick={() => {
                    if (entityType === 'story' || entityType === 'case') {
                      setModal({
                        isVisible: true,
                        sentiment: 'NEUTRAL',
                        contentType:
                          entityType === 'case'
                            ? null
                            : contentTypesMap[sentimentBar],
                      });
                    }
                  }}
                >
                  {formatNumberSignificant(data?.neutral[sentimentBar])}
                </span>
              )}
              {data?.negative[sentimentBar] === 0 ? (
                ''
              ) : (
                <span
                  className={
                    entityType === 'story' || entityType === 'case'
                      ? 'sentiment-bar-negative clickable'
                      : 'sentiment-bar-negative'
                  }
                  style={{
                    width: `${data?.negative[sentimentBar + '_share']}%`,
                  }}
                  onClick={() => {
                    if (entityType === 'story' || entityType === 'case') {
                      setModal({
                        isVisible: true,
                        sentiment: 'NEGATIVE',
                        contentType:
                          entityType === 'case'
                            ? null
                            : contentTypesMap[sentimentBar],
                      });
                    }
                  }}
                >
                  {formatNumberSignificant(data?.negative[sentimentBar])}
                </span>
              )}
            </div>
            <div className="sentiment-bar-stats">
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color positive"></span>
                <span className="sentiment-bar-stats-data">{`${t(
                  'Positive',
                )}: ${
                  data?.positive[sentimentBar + '_share'] === 0
                    ? '0'
                    : data?.positive[sentimentBar + '_share'].toPrecision(4)
                }%`}</span>
              </div>
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color neutral"></span>
                <span className="sentiment-bar-stats-data">{`${t('Neutral')}: ${
                  data?.neutral[sentimentBar + '_share'] === 0
                    ? '0'
                    : data?.neutral[sentimentBar + '_share'].toPrecision(4)
                }%`}</span>
              </div>
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color negative"></span>
                <span className="sentiment-bar-stats-data">{`${t(
                  'Negative',
                )}: ${
                  data?.negative[sentimentBar + '_share'] === 0
                    ? '0'
                    : data?.negative[sentimentBar + '_share'].toPrecision(4)
                }%`}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="widgetLoader">
          <LoaderSmall />
        </div>
      )}
      {modal.isVisible ? (
        <WidgetContentModal
          infoModal={modal.isVisible}
          tabIndex={1}
          isChart={true}
          platformOption={null}
          type={null}
          subType={null}
          stateAffiliatedId={null}
          countryId={countries?.length > 0 ? countries : null}
          audienceRange={null}
          narrative={report ? report : entity}
          sourceGroup={
            sourceGroups?.length > 0
              ? sourceGroups?.map((sourceGroup) => sourceGroup?.value)
              : null
          }
          source={null}
          sentimentOptions={[modal.sentiment]}
          eventIdsOptions={entityType === 'story' ? [entity.id] : null}
          startDate={startDate}
          endDate={endDate}
          contentTypeOptions={modal.contentType ? [modal.contentType] : null}
          // isShare={isShare}
          onChange={(bool) => {
            setModal({ isVisible: bool, country: null });
          }}
          showAddToActions={entityType === 'story' || entityType === 'case'}
          onAddToCase={(bool) => {
            setAddToCaseModal({
              isOpen: bool,
              contentTypes: modal.contentType ? [modal.contentType] : null,
              sentiment: [modal.sentiment],
            });
          }}
          onAddToGroup={(bool) => {
            setAddToGroupModal({
              isOpen: bool,
              contentTypes: modal.contentType ? [modal.contentType] : null,
              sentiment: [modal.sentiment],
            });
          }}
        />
      ) : (
        ''
      )}
      {addToCaseModal.isOpen ? (
        <AddToNarrativeModal
          isOpen={addToCaseModal.isOpen}
          deduplication={false}
          caseId={entityType === 'case' ? entity?.id : report?.id}
          eventIds={entityType === 'case' ? null : [entity?.id]}
          areAllMessagesSelected={true}
          contentTypes={addToCaseModal.contentTypes}
          sentiment={addToCaseModal.sentiment}
          startDate={startDate}
          endDate={endDate}
          sourceGroups={sourceGroups}
          countries={countries}
          onChange={(bool) => {
            setAddToCaseModal({
              isOpen: bool,
              contentTypes: null,
              sentiment: null,
            });
          }}
        />
      ) : (
        ''
      )}
      {addToGroupModal.isOpen ? (
        <AddToSourceGroupModal
          isOpen={addToGroupModal.isOpen}
          deduplication={false}
          areAllMessagesSelected={true}
          contentTypes={addToGroupModal.contentTypes}
          sentiment={addToGroupModal.sentiment}
          startDate={startDate}
          endDate={endDate}
          sourceGroups={sourceGroups}
          countries={countries}
          narrative={entityType === 'case' ? entity : report}
          eventIds={entityType === 'case' ? null : [entity?.id]}
          onChange={(bool) => {
            setAddToGroupModal({
              isOpen: bool,
              contentTypes: null,
              sentiment: null,
            });
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
};
