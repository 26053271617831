import { useTranslation } from 'react-i18next';
import Tooltip from '../../components/Tooltip/Tooltip';
import { useCallback, useEffect, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API } from '../../API';
import Switch from '../../components/Switch';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { Enable2FA } from './Enable2FA';
import { BasicSettings } from './BasicSettings';
import { SecuritySettings } from './SecuritySettings';
import { UserSettingsContext } from './contexts/UserSettingsContext';
import { ChangePasswordSettings } from './ChangePasswordSettings';
import { BillingAndSubscriptions } from './BillingAndSubscriptions';

import styles from './UserSettingsPage.module.scss';

export const UserSettingsPage = () => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useCurrentUser();
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const { user, fetchCurrentUser, error } = useContext(UserSettingsContext);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  if (!user) {
    return <></>;
  }

  return (
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb">
          <div className="breadcrumb">
            <span>
              <Link to="/users/settings">{t('Settings')}</Link>
            </span>
          </div>
        </div>
      </div>

      <BasicSettings />
      <ChangePasswordSettings />
      <SecuritySettings />
      <BillingAndSubscriptions />
    </div>
  );
};
