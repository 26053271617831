import React from 'react';
import styles from './LoaderSmall.module.scss';
import oiiaGif from '../../assets/oiia-oiiaoiia.gif';
import polishCowGif from '../../assets/polish-cow.gif';
import {useCurrentUser} from "../../contexts/CurrentUser";

export const LoaderSmall = () => {
  const [currentUser] = useCurrentUser();
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const isFunnyLoaderEnabled = featureFlags?.includes('funny_loader');

  if (!isFunnyLoaderEnabled) {
    return (
      <span className={styles.spinner}>
        <span></span>
        <span></span>
        <span></span>
      </span>
    );
  }

  const selectedGif = Math.random() < 0.5 ? oiiaGif : polishCowGif;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <img
          width={30}
          height={30}
          src={selectedGif}
          alt="Loading..."
        />
        <img
          width={30}
          height={30}
          src={selectedGif}
          alt="Loading..."
        />
        <img
          width={30}
          height={30}
          src={selectedGif}
          alt="Loading..."
        />
      </div>
    </div>
  );
};
