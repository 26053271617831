import { useTranslation} from 'react-i18next';
import { useState, useEffect, useCallback, useMemo  } from 'react';
import Async from 'react-select/async';

import { API } from '../../../API';
import './Filter.scss';

export const SourceGroupsChooser = ({onChange, selected=[], onClose}) => {
  const { t } = useTranslation();
  const [sourceGroups, setSourceGroups] = useState([]);

  const [groups, setGroups] = useState(null);

  const page = 1;
  const pageSize = 30;

  const getSelectedSourceGroups = (selected) => {
    if (selected.length === 0) {
      setSourceGroups([])
      return
    }

    const urlParams = new URLSearchParams();

    selected.forEach(id => {
      urlParams.append('ids', id)
    });

    return API.fetch('GET', `/API/v1/groups?${urlParams.toString()}`).then(data => {
      setSourceGroups(data.objects
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((group) => {
          return {
            value: group.id,
            label: group.name,
          };
        }))
    })
  }

  useMemo(() => {
    getSelectedSourceGroups(selected)
  }, [selected])

  const fetchGroupsOptions = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', pageSize);
    urlParams.set('page', page);

    API.fetch('GET', `/API/v1/groups?${urlParams.toString()}`).then(
      (data) => {
        setGroups(data.objects.map((group) => {
          return {value: group.id, label: group.name};
        }));
      },
    );
  }, []);

  useEffect(() => {
    fetchGroupsOptions()
  }, [])

  const getGroups = (inputValue, callback) => {
    const urlParams = new URLSearchParams();
    urlParams.set('q', inputValue);

    return API.fetch('GET', `/API/v1/groups?${urlParams.toString()}`).then((data) => {
      callback(
        data.objects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((group) => {
            return {value: group.id, label: group.name};
          })
      );
    });
  };

  let groupsPromiseTimeout;
  const groupsPromiseOptions = function (inputValue) {
    if (groupsPromiseTimeout !== null) {
      clearTimeout(groupsPromiseTimeout);
    }

    if (! inputValue) {
        return [];
    }

    return new Promise((resolve) => {
      groupsPromiseTimeout = setTimeout(() => {
        getGroups(inputValue, resolve);
      }, 1000);
    });
  }

  if (!groups) return

  return (
    <div className='chooser group-search-filter'>
      <Async
        className="select-filter"
        classNamePrefix="select-filter"
        cacheOptions
        defaultOptions={groups}
        isMulti={true}
        loadOptions={groupsPromiseOptions}
        placeholder={t('Please enter at least 1 symbol')}
        noOptionsMessage={()=> t('Please enter at least 1 symbol')}
        loadingMessage={() => t('Loading...')}
        name="countries"
        value={sourceGroups}
        isLoading={selected.length > 0 && sourceGroups.length === 0}
        onChange={(values) => onChange(values.map((group) => group.value))}
        onMenuClose={() => onClose()}
      />
    </div>
  )
}
