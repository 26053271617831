import { useTranslation } from 'react-i18next';
import { useState, useRef, useCallback, useEffect, startTransition } from 'react';
import { API } from '../../../API';

import { Collapsible } from '../../SourcesPage/Filter/Collapsible';
import { ReactComponent as FilterIcon } from '../../SourcesPage/assets/filter.svg';
import { DoubleRangeSlider } from '../../SourcesPage/Filter/DoubleRangeSlider/DoubleRangeSlider';
import { CountryChooser } from "../../SourcesPage/Filter/CountryChooser";
import { IoCChooser } from '../../SourcesPage/Filter/IoCChooser';
import { BotsChooser } from '../../SourcesPage/Filter/BotsChooser';
import {useClickOutside} from '../../../utils/useClickOutside.jsx'
import {getSelectedCountries} from '../../../utils/countries';
import { AffiliationCountries } from '../../SourcesPage/Filter/AffiliationCountries';
import { ExcludeSwitch } from '../../SearchPage/Filter/ExcludeSwitch';
import '../../SourcesPage/Filter/Filter.scss';
import styles from '../../SourcesPage/Filter/Filter.module.scss';
import { useCurrentUser } from '../../../contexts/CurrentUser';


export const Filter = ({
  onChange,
  countriesFilter,
  audienceGteFilter,
  audienceLteFilter,
  affiliationCountriesFilter,
  IoCsFilter,
  showOnlyBotsFilter,
  countriesExcludeFilter,
  affiliationCountriesExcludeFilter,
  audienceExcludeFilter,
  onlyIcon=false,
  className='',
}) => {
  const { t } = useTranslation();
  const clickRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [countries, setCountries] = useState(countriesFilter || []);
  const [audienceRange, setAudienceRange] = useState([audienceGteFilter, audienceLteFilter]);
  const [affiliationCountries, setAffiliationCountries] = useState(affiliationCountriesFilter || []);
  const [IoCs, setIoCs] = useState(IoCsFilter || []);
  const [showOnlyBots, setShowOnlyBots] = useState(showOnlyBotsFilter || false);
  const [isCountriesOpen, setIsCountriesOpen] = useState(false);
  const [isAudienceOpen, setIsAudienceOpen] = useState(false);
  const [isAffiliationCountriesOpen, setIsAffiliationCountriesOpen] = useState(false);
  const [isIoCsOpen, setIsIoCsOpen] = useState(false);
  const [isShowOnlyBotsOpen, setIsShowOnlyBotsOpen] = useState(false);

  const [currentUser] = useCurrentUser();
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showExclusiveFilters = featureFlags?.includes('exclusive_filters');
  const hideRiskActors = featureFlags?.includes('hide_risk_actors');
  const [countriesExclude, setCountriesExclude] = useState(countriesExcludeFilter || false);
  const [affiliationCountriesExclude, setAffiliationCountriesExclude] = useState(affiliationCountriesExcludeFilter || false);
  const [audienceExclude, setAudienceExclude] = useState(audienceExcludeFilter || false);
  
  let filtersSumm = countries.length + affiliationCountries.length + IoCs.length + (showOnlyBots ? 1 : 0);

  const getAllCountries = useCallback(() => {
    API.fetch('GET', `/API/v1/countries`).then((data) => {
      let selectedCountries = getSelectedCountries(countries, data.objects);
      setCountries(selectedCountries)

      let selectedAffiliationCountries = getSelectedCountries(affiliationCountries, data.objects);
      setAffiliationCountries(selectedAffiliationCountries)
    });
  }, []);

  useEffect(getAllCountries, [getAllCountries]);

  useClickOutside(clickRef, () => {
    if(isActive) {
      setIsActive(false)
    }
  })

  return (
    <div ref={clickRef} className={className ? `${styles.root} ${styles[className]}` : styles.root}>
      <button 
        className={isActive ? `${styles.button} ${styles.filters} ${styles.active}` : `${styles.button} ${styles.filters}`}
        type='button'
        onClick={() => setIsActive(!isActive)}
      >
        {filtersSumm > 0 ? <span className='summ'>{filtersSumm }</span> : ''}
        {onlyIcon ? '' : t('Filters')}
        <FilterIcon className={isActive ? 'iconActive' : 'icon'}/>
      </button>

      <div className={isActive ? `${styles.container} ${styles.show}` : `${styles.container}`}>
        <div className={styles.header}>
          <span>{t('Filter by')}</span>

          <span onClick={()=> {
            if (!isCountriesOpen || !isAudienceOpen || !isAffiliationCountriesOpen || !isIoCsOpen || !isShowOnlyBotsOpen) {
              setIsCountriesOpen(true);
              setIsAudienceOpen(true);
              setIsAffiliationCountriesOpen(true);
              setIsIoCsOpen(true);
              setIsShowOnlyBotsOpen(true);
            } else {
              setIsCountriesOpen(false);
              setIsAudienceOpen(false);
              setIsAffiliationCountriesOpen(false);
              setIsIoCsOpen(false);
              setIsShowOnlyBotsOpen(false);
            }
          }}>
            {isCountriesOpen && isAudienceOpen && isAffiliationCountriesOpen && isIoCsOpen && isShowOnlyBotsOpen ? `${t('Minimize all')}` : `${t('Expand all')}`}
          </span>
        </div>

        <div className={styles.collapsiblesWrapper}>

          <Collapsible
            name='Country'
            summ={countries.length}
            isOpen={isCountriesOpen}
            onChange={(open) => setIsCountriesOpen(open)}
            exclude={countriesExclude}
          >
           {showExclusiveFilters ? <div className={styles.excludeFilterContainer}>
              <ExcludeSwitch
                id='country-switch'
                value={countriesExclude}
                onChange={setCountriesExclude}
                color='red'
              />
            </div> : ''}
            <CountryChooser
              selected={countries}
              onChange={(checklist) => setCountries(checklist)}
              onClose={() => startTransition(() => setIsActive(true))}
            />
          </Collapsible>

          <Collapsible 
            name='Audience'
            isOpen={isAudienceOpen}
            onChange={(open) => setIsAudienceOpen(open)}
            exclude={audienceExclude}
          >
            {showExclusiveFilters ? <div className={styles.excludeFilterContainer}>
              <ExcludeSwitch
                id='audience-switch'
                value={audienceExclude}
                onChange={setAudienceExclude}
                color='red'
              />
            </div> : ''}
            <DoubleRangeSlider
              min={0}
              max={1000000000}
              step={1}
              audienceRange={audienceRange}
              onChange={(range) => setAudienceRange(range)}

            />
          </Collapsible>

          <Collapsible
            name='State-affiliated'
            summ={affiliationCountries.length}
            isOpen={isAffiliationCountriesOpen}
            onChange={(open) => setIsAffiliationCountriesOpen(open)}
            exclude={affiliationCountriesExclude}
          >
            <AffiliationCountries
              selected={affiliationCountries}
              onChange={(value) => setAffiliationCountries(value)}
              onClose={() => startTransition(() => setIsActive(true))}
              onExcludeChange={(bool) => setAffiliationCountriesExclude(bool)}
              excludeValue={affiliationCountriesExclude}
            />

          </Collapsible>

          {hideRiskActors ? '' : <Collapsible
            name='High risk accounts'
            summ={IoCs.length}
            isOpen={isIoCsOpen}
            onChange={(open) => setIsIoCsOpen(open)}
          >
            <IoCChooser
              selected={IoCs}
              onChange={(checklist) => setIoCs(checklist)}
            />
          </Collapsible>}

          <Collapsible
            name='Inauthentic behavior'
            isOpen={isShowOnlyBotsOpen}
            onChange={(open) => setIsShowOnlyBotsOpen(open)}
          >
            <BotsChooser
              checked={showOnlyBots}
              onChange={(value) => setShowOnlyBots(value)}
            />
          </Collapsible>

        </div>

        <div className={styles.controls}>
          <button 
            className={`${styles.button} ${styles.apply}`}
            type='button'
            onClick={() => {
              onChange(
                countries.map(country => country.value),
                audienceRange,
                affiliationCountries.map(country => country.value),
                IoCs,
                showOnlyBots,
                affiliationCountriesExclude,
                countriesExclude,
                audienceExclude
              )
              setIsActive(false)
            }}
          >
            {t('Apply')}
          </button>

          <button 
            type='button'
            className={`${styles.button} ${styles.reset}`}
            onClick={() => {
              setAudienceRange([0, 1000000000]);
              setCountries([]);
              setAffiliationCountries([]);
              setIoCs([]);
              setShowOnlyBots(false);
              setAffiliationCountriesExclude(false);
              setCountriesExclude(false);
              setAudienceExclude(false);
            }}
          >
            {t('Reset all')}
          </button>
        </div>
      </div>


    </div>
  )
}