import { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { API } from '../../API';
import { Paginator } from '../../components/Paginator/Paginator';
import { Loader } from '../../components/Loader/Loader';
import { Search } from '../../components/Search/Search';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon} from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon} from '../../assets/sort.svg';
import { ReactComponent as HeartRegularIcon } from '../../assets/heart-regular.svg';
import { ReactComponent as HeartSolidIcon } from '../../assets/heart-solid.svg';
import { Tooltip } from 'react-tooltip';
import { Filter } from './Filter/Filter';
import './WorkspacesPage.scss';


export function WorkspacesPage() {
  const { t } = useTranslation();
  const [workspaces, setWorkspaces] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const page = searchParams.get('page') || 1;
  const searchQuery = searchParams.get('q');
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [sorting, setSorting] = useState({isAscending: false, fieldName: 'id'});

  const [statuses, setStatuses] = useState([]);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);

  const sortingQuery = sorting
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;

  useEffect(() => {
    if (location.state?.reload && location.state?.reload === true) {
      setSorting({isAscending: false, fieldName: 'id'});
      setStatuses([]);
      setShowOnlyFavorites(false);
      setWorkspaces(null);
    }
  }, [location.state]);

  const fetchData = useCallback(
    (statuses, showOnlyFavorites) => {
      const urlParams = new URLSearchParams();

      setWorkspaces(null);

      urlParams.set('size', '100');
      urlParams.set('page', page);

      if (searchQuery) {
        urlParams.set('q', searchQuery);
      }

      if (sorting) {
        if (Array.isArray(sorting.fieldName)) {
          sorting.fieldName.forEach((element) => {
            urlParams.append(
              'sorting',
              `${sorting.isAscending ? '' : '-'}${element}`,
            );
          });
        } else {
          urlParams.set('sorting', sortingQuery);
        }
      }

      if (statuses?.length > 0) {
        statuses.forEach((status) => {
          urlParams.append('statuses', status);
        });
      }

      if (showOnlyFavorites) {
        urlParams.set('is_favorite_filter', 'true');
      }

      API.fetch('GET', `/API/v1/workspaces?${urlParams.toString()}`).then(
        (data) => {
          setWorkspaces(data);
        },
      );
    },
    [page, searchQuery, sorting, statuses, showOnlyFavorites],
  );

  useEffect(() => {
    fetchData(statuses, showOnlyFavorites);
  }, [
    searchParams,
    statuses,
    page,
    sortingQuery,
    showOnlyFavorites,
    fetchData,
  ]);

  const handleSudo = (workspace) => {
    localStorage.setItem('workspace_id', workspace.id);
    localStorage.setItem('workspace_name', workspace.name);

    navigate('/');
  };

  const handleFavorite = (workspace) => {
    const url = `/API/v1/workspaces/${workspace.id}/favorite`;
    const method = workspace.is_favorite ? 'DELETE' : 'POST';

    API.fetch(method, url).then(() => {
      let newWorkspacesList = workspaces.objects.map((item) => {
        if (item.id === workspace.id) {
          item.is_favorite = !item.is_favorite;
        }
        return item;
      });
      setWorkspaces({ ...workspaces, objects: newWorkspacesList });
    });
  };

  if (!workspaces) {
    return <Loader />;
  }

  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'id',
    },
    {
      name: t('Name'),
      value: 'name',
    },
    {
      name: t('Date created'),
      value: 'date_created',
    },
  ];

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon />,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon />,
    },
  ];

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <span>{t('Workspaces')}</span>
        </div>
      </div>

      <div className="next-card-header">
        <div className="workspaces-search-wrapper">
          <Search
            className="workspaces-search-input"
            onChange={(value) => {
              setSearchParams({ q: value, page: 1 });
            }}
          />

          <DropdownMenu
            isOpen={dropdownMenu}
            header={t('Sort by')}
            onClick={() => setDropdownMenu(!dropdownMenu)}
            buttonName={t('Sort')}
            icon={<SortIcon />}
          >
            <Radiobutton
              itemsList={sortingMenu}
              current={sorting.fieldName}
              onChange={(value) => setSorting({ ...sorting, fieldName: value })}
            />

            <SortingOrder
              itemsList={sortingOrder}
              onClick={(value) =>
                setSorting({ ...sorting, isAscending: value })
              }
              current={sorting.isAscending}
            />
          </DropdownMenu>
          <Filter
            statusesFilter={statuses}
            showOnlyFavoritesFilter={showOnlyFavorites}
            onChange={(statuses, showOnlyFavorites) => {
              setStatuses(statuses);
              setShowOnlyFavorites(showOnlyFavorites);
            }}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <table className="workspaces">
            <thead>
              <tr>
                <td>#</td>
                <td>{t('Name')}</td>
                <td>{t('Description')}</td>
                <td>{t('Status')}</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {workspaces.objects.map((workspace) => {
                return (
                  <tr key={workspace.id}>
                    <td>{workspace.id}</td>
                    <td className="name">{workspace.name}</td>
                    <td className="description">{workspace.subscribers}</td>
                    <td className="status">{workspace.status ?? '-'}</td>
                    <td className="link">
                      <button onClick={() => handleSudo(workspace)}>
                        {t('Sudo')}
                      </button>
                    </td>
                    <td className="link">
                      <button
                        onClick={(e) => handleFavorite(workspace)}
                        type="button"
                        data-tooltip-id={`favorite-tooltip-${workspace.id}`}
                        data-tooltip-content={
                          workspace.is_favorite
                            ? t('Remove from favorites')
                            : t('Add to favorites')
                        }
                        data-tooltip-place="bottom"
                      >
                        {workspace.is_favorite ? (
                          <HeartSolidIcon />
                        ) : (
                          <HeartRegularIcon />
                        )}
                      </button>
                      <Tooltip
                        id={`favorite-tooltip-${workspace.id}`}
                        style={{ fontSize: '14px' }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Paginator
        size={workspaces.size}
        page={workspaces.page}
        total={workspaces.total}
      />
    </div>
  );
}
