import cn from 'classnames';
import debounce from 'lodash.debounce';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../pages/SearchPage/assets/search.svg';

import searchIcon from './assets/search.svg';
import styles from './Search.module.scss';

export const Search = ({ className = '', onChange, debounceWait=300 }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  
  useEffect(() => {
    setSearchQuery(searchParams.get('q') || '');
  }, [searchParams]);
  

  const handleChange = useMemo(
    () =>
      debounce(
        (q) => {
          setSearchParams({ q });
        },
        debounceWait,
        { maxWait: 3000 },
      ),
    [setSearchParams],
  );

  return (
    <div className={cn(styles.root, className)}>
      <SearchIcon 
        className={styles.icon}
        onClick={(e) => {
          e.preventDefault();
          onChange(searchQuery)
        }}
      />

      <input
        type="search"
        placeholder={t('Search here')}
        className={styles.input}
        defaultValue={searchParams.get('q') ?? searchQuery}
        onChange={(e) =>  {
          setSearchQuery(e.target.value)
        }}
        onKeyDown={useCallback((event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            if(onChange) {
              onChange(event.target.value)
            } else {
              setSearchParams({q: event.target.value})
            }
            
          }
        }, [])}
      />
    </div>
  );
};
