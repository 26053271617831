import { useState, useCallback, useEffect, useRef } from 'react';
import { API } from '../../../API';
import { Paginator } from '../../../components/Paginator/Paginator';
import { Loader } from '../../../components/Loader/Loader';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AnchorIcon } from '../../../assets/anchor.svg';
import { ReactComponent as ArrowIcon } from '../../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../../NarrativePage/assets/arrowDown.svg';
import { LinkedActorsTable } from './LinkedActorsTable';
import { Filter } from './Filter';
import { DropdownMenu } from '../../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../../components/DropdownMenu/SortingOrder';
import { ReactComponent as SortIcon} from '../../../assets/sort.svg';
import styles from '../SourcePage.module.scss';
import { set } from 'date-fns';

export const LinkedActors = ({ sourceId }) => {
  const { t } = useTranslation();

  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [linkedActorsWidget, setLinkedActorsWidget] = useState(null);
  const [inbound, setInbound] = useState(null);
  const [outbound, setOutbound] = useState(null);
  const [inboundPage, setInboundPage] = useState(1);
  const [outboundPage, setOutboundPage] = useState(1);
  const [countries, setCountries] = useState(null);
  const [showOnlyBots, setShowOnlyBots] = useState(false);
  const [IoCs, setIoCs] = useState(null);
  const [affiliationCountries, setAffiliationCountries] = useState(null);
  const [audience_gte, setAudienceGte] = useState(null);
  const [audience_lte, setAudienceLte] = useState(null);
  const [countriesExclude, setCountriesExclude] = useState(null);
  const [audienceExclude, setAudienceExclude] = useState(null);
  const [affiliationCountriesExclude, setAffiliationCountriesExclude] = useState(null);
  const [sorting, setSorting] = useState({isAscending: false, fieldName: 'mentions_count'});
  const abortControllerRef = useRef();
  const [changeTabs, setChangeTabs] = useState(true);

  const size = 10;

  const fetchLinkedActorsData = useCallback((
    countries,
    showOnlyBots,
    IoCs,
    affiliationCountries,
    audience_gte,
    audience_lte,
    countriesExclude,
    audienceExclude,
    affiliationCountriesExclude,
    sorting,
    changeTabs,
  ) => {

    setInbound(null);
    setOutbound(null);
    const urlParams = new URLSearchParams();
    urlParams.set('size', size);
    let excludingFilters = [];

    let sortingQuery = sorting.fieldName
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;
  
    if (countries?.length > 0) {
      countries.forEach((country) => {
        urlParams.append('origin_country_ids', country);
      });
    }

    if(affiliationCountries?.length > 0 && affiliationCountries[0] === 'all') {
      urlParams.append('state_affiliated_all_countries', true);
      if (affiliationCountriesExclude) {
        excludingFilters.push('source_state_affiliated_country_ids');
      }
    } else if (affiliationCountries?.length > 0) {
      affiliationCountries.forEach((country) => {
        urlParams.append('state_affiliated_country_ids', country
        );
      });

      if (affiliationCountriesExclude) {
        urlParams.append('exclude_state_affiliated_country_ids', true);
      }
    }

    if (IoCs?.length > 0) {
      IoCs.forEach((IoC) => {
        urlParams.append('discreditated_entity_types', IoC);
      });
    }

    if (showOnlyBots) {
      urlParams.append('is_bot', true);
    }

    if (audience_gte !== null && audience_gte?.length > 0) {
      urlParams.append('audience_gte', audience_gte);
    }

    if (audience_lte !== null && audience_lte?.length > 0) {
      urlParams.append('audience_lte', audience_lte);
    }

    if (countriesExclude === true) {
      excludingFilters.push('origin_country_ids');
    }

    if (audienceExclude === true) {
      excludingFilters.push('audience_lte', 'audience_gte');
    }

    if (excludingFilters?.length > 0) {
      excludingFilters.forEach((filter) => {
        urlParams.append('exclusive_filters', filter);
      });
    }
    
    if (sorting) {
      if (Array.isArray(sorting.fieldName)) {
        sorting.fieldName.forEach((element) => {
          urlParams.append(
            'sorting',
            `${sorting.isAscending ? '' : '-'}${element}`,
          );
        });
      } else {
        urlParams.set('sorting', sortingQuery);
      }
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;


    Promise.all([
      API.fetch(
        'GET',
        `/API/v1/sources/${sourceId}/connections/inbound?${urlParams.toString()}&page=${inboundPage}`, null, null, signal
      ),
      API.fetch(
        'GET',
        `/API/v1/sources/${sourceId}/connections/outbound?${urlParams.toString()}&page=${outboundPage}`, null, null, signal
      ),
    ])
      .then(([inboundData, outboundData]) => {
        if (changeTabs) {
          if (inboundData.objects?.length > 0) {
            setLinkedActorsWidget('inbound');
          } else if (outboundData.objects?.length > 0) {
            setLinkedActorsWidget('outbound');
          } else {
            setLinkedActorsWidget('inbound');
          }
        }

        setInbound(inboundData);
        setOutbound(outboundData);
      })
      .catch((error) => {
        // Handle error if any
        console.error('Error fetching data:', error);
      });
  }, [sourceId]);


  const fetchInbound = (
    countries,
    showOnlyBots,
    IoCs,
    affiliationCountries,
    audience_gte,
    audience_lte,
    countriesExclude,
    audienceExclude,
    affiliationCountriesExclude,
    sorting,
    page,
  ) => {
    setInbound(null);

    const urlParams = new URLSearchParams();
    urlParams.set('size', size);
    urlParams.set('page', page);

    let sortingQuery = sorting.fieldName
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;

    let excludingFilters = [];
  
    if (countries?.length > 0) {
      countries.forEach((country) => {
        urlParams.append('origin_country_ids', country);
      });
    }

    if(affiliationCountries?.length > 0 && affiliationCountries[0] === 'all') {
      urlParams.append('state_affiliated_all_countries', true);
      if (affiliationCountriesExclude) {
        excludingFilters.push('source_state_affiliated_country_ids');
      }
    } else if (affiliationCountries?.length > 0) {
      affiliationCountries.forEach((country) => {
        urlParams.append('state_affiliated_country_ids', country
        );
      });

      if (affiliationCountriesExclude) {
        urlParams.append('exclude_state_affiliated_country_ids', true);
      }
    }

    if (IoCs?.length > 0) {
      IoCs.forEach((IoC) => {
        urlParams.append('discreditated_entity_types', IoC);
      });
    }

    if (showOnlyBots) {
      urlParams.append('is_bot', true);
    }

    if (audience_gte !== null && audience_gte?.length > 0) {
      urlParams.append('audience_gte', audience_gte);
    }

    if (audience_lte !== null && audience_lte?.length > 0) {
      urlParams.append('audience_lte', audience_lte);
    }

    if (countriesExclude === true) {
      excludingFilters.push('origin_country_ids');
    }

    if (audienceExclude === true) {
      excludingFilters.push('audience_lte', 'audience_gte');
    }

    if (excludingFilters?.length > 0) {
      excludingFilters.forEach((filter) => {
        urlParams.append('exclusive_filters', filter);
      });
    }

    if (sorting) {
      if (Array.isArray(sorting.fieldName)) {
        sorting.fieldName.forEach((element) => {
          urlParams.append(
            'sorting',
            `${sorting.isAscending ? '' : '-'}${element}`,
          );
        });
      } else {
        urlParams.set('sorting', sortingQuery);
      }
    }

    API.fetch(
      'GET',
      `/API/v1/sources/${sourceId}/connections/inbound?${urlParams.toString()}`,
    ).then(setInbound);
  };

  const fetchOutbound = (
    countries,
    showOnlyBots,
    IoCs,
    affiliationCountries,
    audience_gte,
    audience_lte,
    countriesExclude,
    audienceExclude,
    affiliationCountriesExclude,
    sorting,
    page,
  ) => {
    setOutbound(null);

    const urlParams = new URLSearchParams();
    urlParams.set('size', size);
    urlParams.set('page', page);

    let sortingQuery = sorting.fieldName
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;

    let excludingFilters = [];
  
    if (countries?.length) {
      countries.forEach((country) => {
        urlParams.append('origin_country_ids', country);
      });
    }

    if(affiliationCountries?.length > 0 && affiliationCountries[0] === 'all') {
      urlParams.append('state_affiliated_all_countries', true);
      if (affiliationCountriesExclude) {
        excludingFilters.push('source_state_affiliated_country_ids');
      }
    } else if (affiliationCountries?.length > 0) {
      affiliationCountries.forEach((country) => {
        urlParams.append('state_affiliated_country_ids', country
        );
      });

      if (affiliationCountriesExclude) {
        excludingFilters.push('source_state_affiliated_country_ids');
      }
    }

    if (IoCs?.length) {
      IoCs.forEach((IoC) => {
        urlParams.append('discreditated_entity_types', IoC);
      });
    }

    if (showOnlyBots) {
      urlParams.append('is_bot', true);
    }

    if (audience_gte !== null && audience_gte?.length > 0) {
      urlParams.append('audience_gte', audience_gte);
    }

    if (audience_lte !== null && audience_lte?.length > 0) {
      urlParams.append('audience_lte', audience_lte);
    }

    if (countriesExclude === true) {
      excludingFilters.push('origin_country_ids');
    }

    if (audienceExclude === true) {
      excludingFilters.push('audience_lte', 'audience_gte');
    }

    if (excludingFilters?.length > 0) {
      excludingFilters.forEach((filter) => {
        urlParams.append('exclusive_filters', filter);
      });
    }

    if (sorting) {
      if (Array.isArray(sorting.fieldName)) {
        sorting.fieldName.forEach((element) => {
          urlParams.append(
            'sorting',
            `${sorting.isAscending ? '' : '-'}${element}`,
          );
        });
      } else {
        urlParams.set('sorting', sortingQuery);
      }
    }
    API.fetch(
      'GET',
      `/API/v1/sources/${sourceId}/connections/outbound?${urlParams.toString()}`,
    ).then(setOutbound);
  };

  useEffect(() => {
    fetchLinkedActorsData(
      countries,
      showOnlyBots,
      IoCs,
      affiliationCountries,
      audience_gte,
      audience_lte,
      countriesExclude,
      audienceExclude,
      affiliationCountriesExclude,
      sorting,
      changeTabs,
    )
  }, [
    countries,
    showOnlyBots,
    IoCs,
    affiliationCountries,
    audience_gte,
    audience_lte,
    countriesExclude,
    audienceExclude,
    affiliationCountriesExclude,
    sorting,
    changeTabs,
    fetchLinkedActorsData
  ]);

  const sortingMenu = [
    {
      name: t('Links'),
      value: 'mentions_count',
    },
    {
      name: t('Audience'),
      value: 'audience',
    },
  ];

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon/>,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon/>,
    },
  ]

  return (
    <div className="report-section">
      <h3 className="trend-chart-controlls">
        <div className="trend-chart-controlls__tabs">
          {t('Linked actors')}
          {linkedActorsWidget ? (
            <button
              type="button"
              className={linkedActorsWidget === 'inbound' ? 'active' : ''}
              onClick={() => setLinkedActorsWidget('inbound')}
            >
              <span>{t('Inbound')}</span>
              <span className={styles.tooltipControls}>
                <Tooltip
                  content={t(
                    'Actors that generated the most reposts, mentions and comments to the current actor',
                  )}
                  position="right"
                >
                  <AnchorIcon />
                </Tooltip>
              </span>
            </button>
          ) : (
            ''
          )}
          {linkedActorsWidget ? (
            <button
              type="button"
              className={linkedActorsWidget === 'outbound' ? 'active' : ''}
              onClick={() => setLinkedActorsWidget('outbound')}
            >
              <span>{t('Outbound')}</span>
              <span className={styles.tooltipControls}>
                <Tooltip
                  content={t(
                    'Actors which were the most reposted, mentioned and commented by the current actor',
                  )}
                  position="right"
                >
                  <AnchorIcon />
                </Tooltip>
              </span>
            </button>
          ) : (
            ''
          )}
        </div>
        <div className="trend_chart_filters">
          <DropdownMenu
            isOpen={dropdownMenu}
            header={t('Sort by')}
            onClick={() => setDropdownMenu(!dropdownMenu)}
            // buttonName={t('Sort')}
            icon={<SortIcon />}
            menuClassName="only-icon"
          >
            <Radiobutton
              itemsList={sortingMenu}
              current={sorting.fieldName}
              onChange={(value) => {
                setSorting({ ...sorting, fieldName: value });
                setChangeTabs(false);
                setInboundPage(1);
                setOutboundPage(1);
              }}
            />

            <SortingOrder
              itemsList={sortingOrder}
              onClick={(value) => {
                setSorting({ ...sorting, isAscending: value });
                setChangeTabs(false);
                setInboundPage(1);
                setOutboundPage(1);
              }}
              current={sorting.isAscending}
            />
          </DropdownMenu>
          <Filter
            onlyIcon={true}
            className="onlyIcon"
            countriesFilter={countries}
            showOnlyBotsFilter={showOnlyBots}
            IoCsFilter={IoCs}
            affiliationCountriesFilter={affiliationCountries}
            audienceGteFilter={audience_gte}
            audienceLteFilter={audience_lte}
            affiliationCountriesExcludeFilter={affiliationCountriesExclude}
            countriesExcludeFilter={false}
            audienceExcludeFilter={false}
            onChange={(
              countries,
              audienceRange,
              affiliationCountries,
              IoCs,
              showOnlyBots,
              affiliationCountriesExclude,
              countriesExclude,
              audienceExclude,
            ) => {
              setCountries(countries);
              setAudienceGte(audienceRange[0]);
              setAudienceLte(audienceRange[1]);
              setAffiliationCountries(affiliationCountries);
              setIoCs(IoCs);
              setShowOnlyBots(showOnlyBots);
              setAffiliationCountriesExclude(affiliationCountriesExclude);
              setCountriesExclude(countriesExclude);
              setAudienceExclude(audienceExclude);
              setInboundPage(1);
              setOutboundPage(1);
              setChangeTabs(false);
            }}
          />
        </div>
      </h3>

      <div className="card">
        <div className="">
          {!inbound || !outbound ? (
            <div className={styles.card}>
              <Loader />
            </div>
          ) : inbound?.objects?.length > 0 || outbound?.objects?.length > 0 ? (
            <div className={styles.linkedActorsWrapper}>
              {linkedActorsWidget === 'inbound' &&
              inbound?.objects?.length > 0 ? (
                <div className={styles.sources}>
                  <LinkedActorsTable data={inbound} tableId={'inbound'} />
                  <div className={styles.paginator}>
                    <Paginator
                      size={size}
                      page={inboundPage}
                      total={inbound.total}
                      id="inbound"
                      onPageChange={(page) => {
                        setInboundPage(page);
                        fetchInbound(
                          countries,
                          showOnlyBots,
                          IoCs,
                          affiliationCountries,
                          audience_gte,
                          audience_lte,
                          countriesExclude,
                          audienceExclude,
                          affiliationCountriesExclude,
                          sorting,
                          page,
                        );
                      }}
                    />
                  </div>
                </div>
              ) : linkedActorsWidget === 'inbound' &&
                inbound?.objects?.length === 0 ? (
                <div className={styles.header}>
                  <div className={styles.noData}>
                    {t('There is no data to display')}
                  </div>
                </div>
              ) : (
                ''
              )}

              {linkedActorsWidget === 'outbound' &&
              outbound?.objects?.length > 0 ? (
                <div className={styles.sources}>
                  <LinkedActorsTable data={outbound} tableId={'outbound'} />
                  <div className={styles.paginator}>
                    <Paginator
                      size={10}
                      page={outboundPage}
                      total={outbound.total}
                      id="outbound"
                      onPageChange={(page) => {
                        setOutboundPage(page);
                        fetchOutbound(
                          countries,
                          showOnlyBots,
                          IoCs,
                          affiliationCountries,
                          audience_gte,
                          audience_lte,
                          countriesExclude,
                          audienceExclude,
                          affiliationCountriesExclude,
                          sorting,
                          page,
                        );
                      }}
                    />
                  </div>
                </div>
              ) : linkedActorsWidget === 'outbound' &&
                outbound?.objects?.length === 0 ? (
                <div className={styles.header}>
                  <div className={styles.noData}>
                    {t('There is no data to display')}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className={styles.header}>
              <div className={styles.noData}>
                {t('There is no data to display')}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
