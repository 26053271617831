import { useState, useEffect, useCallback, useReducer, useRef } from 'react';
import { useSearchParams, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { API } from '../../API';
import { Loader } from '../../components/Loader/Loader';
import { SourcesTable } from '../../components/SourcesTable/SourcesTable';
import { Search } from '../../components/Search/Search';
import { Filter } from './Filter/Filter';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import styles from './SourcesPage.module.scss';

const sortingFieldNames = {
  ID: 'id',
  AUDIENCE: 'audience',
  ACTOR: 'name',
  PLATFORM: 'source_type',
  STATUS: 'status',
  URL: 'url',
  COUNTRY: 'country',
  RELEVANCE: 'relevance',
};

const sortingFieldNamesBack = {
  'id': 'ID',
  'audience': 'AUDIENCE',
  'name': 'ACTOR',
  'source_type': 'PLATFORM',
  'status': 'STATUS',
  'url': 'URL',
  'country': 'COUNTRY',
  'relevance': 'RELEVANCE',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'APPLY_FILTER': {
      return Object.assign({}, state, {
        platforms: action.payload.platforms,
        countries: action.payload.countries,
        audience_lte: action.payload.audience_lte,
        audience_gte: action.payload.audience_gte,
        status: action.payload.status,
        affiliationCountries: action.payload.affiliationCountries,
        IoCs: action.payload.IoCs,
        showOnlyBots: action.payload.showOnlyBots,
        sourceTags: action.payload.sourceTags,
        affiliationCountriesExclude: action.payload.affiliationCountriesExclude,
        countriesExclude: action.payload.countriesExclude,
        audienceExclude: action.payload.audienceExclude,
        page: action.payload.page
      })
    }

    case 'UPDATE_QUERY': {
      return Object.assign({}, state, {
        searchQuery: action.payload,
      })
    }

    case 'SORT_BY': {
      return Object.assign({}, state, {
        sorting: {...state.sorting, fieldName: action.fieldName}
      })
    }

    case 'IS_ASCENDING': {
      return Object.assign({}, state, {
        sorting: {...state.sorting, isAscending: action.isAscending}
      })
    }

    case 'CHANGE_PAGE': 
    return Object.assign({}, state, {
      page: action.page,
    })
    default: 
      return state;
  }
}

export function SourcesPage() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const sortingParam = searchParams.get('sorting') == null ? '-relevance' : searchParams.get('sorting');
  let urlSorting = sortingParam;
  let location = useLocation();

  let defineAscending = true; 
  if (urlSorting.startsWith('-')) {
    defineAscending = false;
    urlSorting = urlSorting.slice(1); 
  }
 
  const [state, dispatch] = useReducer(reducer, {
    sorting: {isAscending: defineAscending, fieldName: sortingFieldNamesBack[urlSorting]},
    searchQuery: searchParams.get('q') || '',
    platforms: searchParams.getAll('source_types') || [],
    countries: searchParams.getAll('origin_country_ids') || [],
    audience_lte: searchParams.get('audience_lte') || null,
    audience_gte: searchParams.get('audience_gte') || null,
    status: searchParams.getAll('statuses').length ? searchParams.getAll('statuses') : ['ACTIVE'],
    affiliationCountries: searchParams.getAll('affiliations') || [],
    IoCs: searchParams.getAll('IoC') || [],
    showOnlyBots: searchParams.get("showOnlyBots") || false,
    sourceTags: searchParams.getAll('tags') || [],
    affiliationCountriesExclude: searchParams.get('affiliations_exclude') !== null ? true : false,
    countriesExclude: searchParams.get('countries_exclude') !== null ? true : false,
    audienceExclude: searchParams.get('audience_exclude') !== null ? true : false,
    page: searchParams.get('page') || 1
  });

  useEffect(() => {
      if (location.state?.reload && location.state?.reload === true) {
      dispatch({
        type: 'APPLY_FILTER',
        payload: {
          platforms: [],
          countries: [],
          audience_lte: null,
          audience_gte: null,
          status: ['ACTIVE'],
          affiliationCountries: [],
          IoCs: [],
          showOnlyBots: false,
          sourceTags: [],
          affiliationCountriesExclude: false,
          countriesExclude: false,
          audienceExclude: false,
          page: 1,
        },
      });
      dispatch({ type: 'UPDATE_QUERY', payload: '' });
      dispatch({ type: 'SORT_BY', fieldName: 'RELEVANCE' });
      dispatch({ type: 'IS_ASCENDING', isAscending: false });
      }
    }, [location.state]);


  // const searchQuery = searchParams.get('q') || '';
  const [sources, setSources] = useState(null);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const navigate = useNavigate();

  const sortingQuery = state.sorting.fieldName
    ? `${state.sorting.isAscending ? '' : '-'}${sortingFieldNames[state.sorting.fieldName]}`
    : null;


  const createURL = () => {
    const urlParams = new URLSearchParams();

    if (state.searchQuery) {
      urlParams.set('q', state.searchQuery);
    }

    if (state.platforms && state.platforms.length) {
      state.platforms.forEach((platform) => {
        urlParams.append('source_types', platform);
      });
    }

    if (state.countries?.length) {
      state.countries.forEach((country) => {
        urlParams.append('origin_country_ids', country);
      });

      if(state.countriesExclude === true) {
        urlParams.set('countries_exclude', state.countriesExclude)
      }
    }

    if (state.audience_lte !== null && state.audience_lte < 1000000000) {
      urlParams.set('audience_lte', state.audience_lte);
    }

    if (state.audience_gte !== null && state.audience_gte > 0) {
      urlParams.set('audience_gte', state.audience_gte);
    }

    if ((state.audience_lte !== null && state.audience_lte < 1000000000) || (state.audience_gte !== null && state.audience_gte > 0)) {
      if(state.audienceExclude === true) {
        urlParams.set('audience_exclude', state.audienceExclude)
      }
    }

    if(state.status.length) {
      state.status.forEach((status_) => {
        urlParams.append('statuses', status_);
      });
    }

    if(state.affiliationCountries?.length > 0 && state.affiliationCountries[0] === 'all') {
      urlParams.set('affiliations', 'all')
      if (state.affiliationCountriesExclude === true) {
        urlParams.set('affiliations_exclude', state.affiliationCountriesExclude)
      }
    } else {

      state.affiliationCountries?.forEach(affiliation => {
        urlParams.append('affiliations', affiliation)
      })

      if (state.affiliationCountriesExclude === true) {
        urlParams.set('affiliations_exclude', state.affiliationCountriesExclude)
      }
    }

    if (state.IoCs?.length) {
      state.IoCs.forEach((IoC) => {
        urlParams.append('IoC', IoC);
      });
    }

    if (state.showOnlyBots) {
      urlParams.set('showOnlyBots', state.showOnlyBots);
    }

    if (state.sourceTags) {
      state.sourceTags.forEach(tag => {
        urlParams.append('tags', tag)
      })
    }

    if (state.sorting) {
      if (Array.isArray(state.sorting.fieldName)) {
        state.sorting.fieldName.forEach((element) => {
          urlParams.append(
            'sorting',
            `${state.sorting.isAscending ? '' : '-'}${element}`,
          );
        });
      } else {
        urlParams.set('sorting', sortingQuery);
      }
    }

    urlParams.set('page', state.page);

    return urlParams.toString();
  }

  const abortControllerRef = useRef();
  
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  }, [])

  const fetchData = useCallback(
    (
      platforms,
      countries,
      audience_gte,
      audience_lte,
      status,
      affiliationCountries,
      IoCs,
      showOnlyBots,
      sourceTags,
      sorting,
      searchQuery,
      affiliationCountriesExclude,
      countriesExclude,
      audienceExclude,
      page,
    ) => {
      const urlParams = new URLSearchParams();
      urlParams.set('size', '100');
      urlParams.set('page', page);

      let excludedFilters = [];

      if (searchQuery) {
        urlParams.set('q', searchQuery);
      }

      if (platforms.length) {
        platforms.forEach((platform) => {
          urlParams.append('source_types', platform);
        });
      }

      if (countries.length) {
        countries.forEach((country) => {
          urlParams.append('origin_country_ids', country);
        });
      }

      if (audience_lte !== null && audience_lte.length > 0) {
        urlParams.set('audience_lte', audience_lte);
      }

      if (audience_gte !== null && audience_gte.length > 0) {
        urlParams.set('audience_gte', audience_gte);
      }

      if (status.length) {
        status.forEach((status_) => {
          urlParams.append('statuses', status_);
        });
      }

      if(affiliationCountries?.length > 0 && affiliationCountries[0] === 'all') {
        urlParams.append('state_affiliated', true);
        if (affiliationCountriesExclude) {
          excludedFilters.push('country_ids');
        }
      } else if (affiliationCountries?.length > 0) {
        affiliationCountries.forEach((country) => {
          urlParams.append('country_ids', country
          );
        });
  
        if (affiliationCountriesExclude) {
          excludedFilters.push('country_ids');
        }
      }

      if (IoCs.length) {
        IoCs.forEach((IoC) => {
          urlParams.append('entity_types', IoC);
        });
      }

      if (showOnlyBots) {
        urlParams.set('is_bot', showOnlyBots);
      }

      if (sourceTags.length) {
        sourceTags.forEach((tag) => {
          urlParams.append('tags', tag);
        });
      }
      
      if (countriesExclude === true) {
        excludedFilters.push('origin_country_ids');
      }


      if (audienceExclude === true) {
        excludedFilters.push('audience_lte', 'audience_gte');
      }

      if (excludedFilters.length > 0) {
        excludedFilters.forEach((filter) => {
          urlParams.append('exclusive_filters', filter);
        });
      }

      if (sorting) {
        if (Array.isArray(sorting.fieldName)) {
          sorting.fieldName.forEach((element) => {
            urlParams.append(
              'sorting',
              `${sorting.isAscending ? '' : '-'}${element}`,
            );
          });
        } else {
          urlParams.set('sorting', sortingQuery);
        }
      }

      setSources(null);

      // Check if there's an existing request and abort it
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new AbortController
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      const signal = abortController.signal;

      API.fetch(
        'GET',
        `/API/v1/sources?${urlParams.toString()}`,
        null,
        null,
        signal,
      ).then((data) => {
        setSources(data);
      });
    },
    [state.page, state.searchQuery, state.sorting, sortingQuery],
  );

  useEffect(() => {
    navigate(`?${createURL()}`);
    fetchData(
      state.platforms,
      state.countries,
      state.audience_gte,
      state.audience_lte,
      state.status,
      state.affiliationCountries,
      state.IoCs,
      state.showOnlyBots,
      state.sourceTags,
      state.sorting,
      state.searchQuery,
      state.affiliationCountriesExclude,
      state.countriesExclude,
      state.audienceExclude,
      state.page
    )
  }, [
    state.platforms,
    state.countries,
    state.audience_gte,
    state.audience_lte,
    state.status,
    state.affiliationCountries,
    state.IoCs,
    state.showOnlyBots,
    state.sourceTags,
    state.sorting,
    state.searchQuery,
    state.affiliationCountriesExclude,
    state.countriesExclude,
    state.audienceExclude,
    state.page
  ])

  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'ID',
    },
    {
      name: t('Audience'),
      value: 'AUDIENCE',
    },
    {
      name: t('Platform'),
      value: 'PLATFORM',
    },
    {
      name: t('Name'),
      value: 'ACTOR',
    },
    {
      name: t('Link'),
      value: 'URL',
    },
    {
      name: t('Country'),
      value: 'COUNTRY',
    },
    {
      name: t('Status'),
      value: 'STATUS',
    },
    {
      name: t('Relevance'),
      value: 'RELEVANCE',
    },
  ]

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon/>,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon/>,
    },
  ]


  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <span>{t('Actors')}</span>
        </div>
      </div>

      <div className="next-card-header">
        <div className={ styles.searchWrapper }>
          <Search 
            className={ styles.searchInput }
            debounceWait={1000}
            onChange={value => {
              dispatch({type: 'UPDATE_QUERY', payload: value});
              dispatch({type: 'CHANGE_PAGE', page: 1})
              setSearchParams({ q: value});
              
            }}
          />
          
          <DropdownMenu  
            isOpen={dropdownMenu}
            header={t('Sort by')}
            onClick={() => setDropdownMenu(!dropdownMenu)}
            buttonName={t('Sort')}
            icon={<SortIcon/>}
          >
            <Radiobutton 
              itemsList={sortingMenu}
              current={state.sorting.fieldName}
              onChange={(value) => dispatch({type: 'SORT_BY', fieldName: value})}
            />

            <SortingOrder 
              itemsList={sortingOrder}
              onClick={value => dispatch({type: 'IS_ASCENDING', isAscending: value})}
              current={state.sorting.isAscending}
            />
          </DropdownMenu>


          <Filter
            state={state}
            onChange={(platforms, countries, audience, status, affiliationCountries, IoCs, showOnlyBots, sourceTags, affiliationCountriesExclude, countriesExclude, audienceExclude) => {
            dispatch({type: 'APPLY_FILTER', 
            payload: {
              platforms: platforms, 
              countries: countries, 
              audience_lte: audience[1], 
              audience_gte: audience[0],
              affiliationCountries: affiliationCountries,
              IoCs: IoCs,
              showOnlyBots: showOnlyBots,
              sourceTags: sourceTags,
              affiliationCountriesExclude: affiliationCountriesExclude,
              countriesExclude: countriesExclude,
              audienceExclude: audienceExclude,
              status: status,
              page: 1,
            }})
          }}
          />
        </div>
      </div>
      {sources 
      ? <SourcesTable 
        sources={sources} 
        state={state}
        headerClassName={styles.actorsHeader}
        onPageChange={newPage => dispatch({type: 'CHANGE_PAGE', page: newPage})}/> 
      : <Loader />}
    </div>
  );
}
