import { useEffect, useState, useCallback } from 'react';
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';

import { useCurrentUser } from '../../contexts/CurrentUser';
import { useCurrentQuota } from '../../contexts/CurrentQuota';
import { API } from '../../API';
import { useAuth } from '../../contexts/Auth';
import { Loader } from '../../components/Loader/Loader';
import { ReactComponent as ReportsIcon } from './assets/report.svg';
import { ReactComponent as NarrativesIcon } from './assets/narratives.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as SourcesIcon } from './assets/sources.svg';
import { ReactComponent as LogoutIcon } from './assets/logout.svg';
import { ReactComponent as GroupsIcon } from './assets/database.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as UserStatsIcon } from '../../assets/userStats.svg';
import { ReactComponent as WorkspacesIcon } from '../../assets/workspaces.svg';
import { ReactComponent as UserIcon } from './assets/user.svg';
import { ReactComponent as DashboardsIcon } from '../../assets/dashboards.svg';
import { ReactComponent as ExportIcon } from '../../assets/export.svg';
import { ReactComponent as TopicIcon } from './assets/dashboard.svg';
import { ReactComponent as OsavulIcon } from '../../assets/osavulLogo.svg';
import { ReactComponent as OsavulFullIcon } from '../../assets/osavul-full.svg';

import websocketConnection from '../../websocket';
import { ThreatsLink } from '../ThreatsPage/ThreatsLink';
import { NarrativesModerationLink } from '../NarrativesModerationPage/NarrativesModerationLink';
import Tooltip from '../../components/Tooltip/Tooltip';
import menuIcon from './assets/menu.svg';
import { ToastContainer, toast } from 'react-toastify';
import { ReloadNavLink } from './ReloadNavLink';
import { MaintenanceMessage } from './MaintenanceMessage';
import 'react-toastify/dist/ReactToastify.css';

import styles from './Layout.module.scss';

export function Layout() {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useCurrentUser();
  const [currentQuota, setCurrentQuota] = useCurrentQuota();
  const [websiteConfig, setWebsiteConfig] = useState(null);
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const [maintenanceMessage, setMaintenanceMessage] = useState(null);
  const [isNavToogleChecked, setisNavToogleChecked] = useState(true);
  const [isAiQuotaLow, setIsAiQuotaLow] = useState(false);
  const [isAnalyticalQuotaLow, setIsAnalyticalQuotaLow] = useState(false);
  const [isAIQuotaInsufficient, setIsAIQuotaInsufficient] = useState(false);
  const [isAnalyticalQuotaInsufficient, setIsAnalyticalQuotaInsufficient] = useState(false);
  const [isTrialEnding, setIsTrialEnding] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const calculateTrialDaysLeft = () => {
    if (currentUser?.workspace?.status !== 'TRIAL') {
      return 0;
    }
    const endDate = new Date(currentUser?.workspace?.trial_end_date);
    const currentDate = new Date();
    const diffTime = endDate - currentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const logoutHandler = async () => {
    await auth.signOut();
    setCurrentUser({});
    navigate('/');
  };

  const sudoGetOutHandle = () => {
    localStorage.removeItem('workspace_id');
    localStorage.removeItem('workspace_name');
    navigate('/workspaces');
  };

  const getInitials = () => {
    const firstLatter = currentUser.first_name ? currentUser.first_name[0] : '';
    const lastLatter = currentUser.last_name ? currentUser.last_name[0] : '';

    return `${firstLatter}${lastLatter}`;
  };

  const getWorkspaceQuota = useCallback(() => {
    API.fetch('GET', `/API/v1/workspaces/current/quota`).then((data) => {
      setCurrentQuota(data.quota);
      if (data.quota?.AI?.is_low && data.quota?.AI?.balance > 0) {
        setIsAiQuotaLow(true);
      }

      if (data.quota?.AI?.balance <= 0) {
        setIsAIQuotaInsufficient(true);
      }

      if (data.quota?.ANALYTICAL?.is_low && data.quota?.ANALYTICAL?.balance > 0) {
        setIsAnalyticalQuotaLow(true);
      }

      if (data.quota?.ANALYTICAL?.balance <= 0) {
        setIsAnalyticalQuotaInsufficient(true);
      }
      
    });
  }, [currentUser]);

  useEffect(() => {
    getWorkspaceQuota();
  }, [getWorkspaceQuota]);

  useEffect(() => {
    API.fetch('GET', '/API/v1/users/me').then((data) => {
      setCurrentUser(data);

      if (
        data?.workspace?.status === 'TRIAL' &&
        data?.workspace?.is_trial_ending
      ) {
        setIsTrialEnding(true);
      }
      if (data.language === null) {
        return;
      }

      changeLanguage(data.language.toLowerCase());
    });

    // should be fetched only on init
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem('i18nextLng') === 'ua-UK' ||
      localStorage.getItem('i18nextLng') === 'ru-RU' ||
      localStorage.getItem('i18nextLng') === 'ru' ||
      localStorage.getItem('i18nextLng') === 'uk'
    ) {
      setMaintenanceMessage(websiteConfig?.maintenance_message_uk);
    } else {
      setMaintenanceMessage(websiteConfig?.maintenance_message_en);
    }
  }, [websiteConfig]);

  useEffect(() => {
    API.fetch('GET', '/API/v1/config/website_config').then((websiteConfig) => {
      setWebsiteConfig(websiteConfig);
    });
  }, []);

  useEffect(() => {
    const notifyExportProgress = (notification) => {
      if (notification?.object_type !== 'ASYNC_EXPORT') {
        return;
      }

      let data = notification?.data;

      const Msg = () => (
        <div>
          {t('An export of') +
            ` ${t('export_type_of_' + data.type)} id=${data.object_id} `}
          <Link to={`/exports?object_id=${data.object_id}&types=${data.type}`}>
            {t(`export_status_${data.status}`).toLowerCase()}
          </Link>
        </div>
      );

      const displayMsg = () => {
        toast.success(<Msg />);
      };
      displayMsg();
    };

    websocketConnection.addHandler('ASYNC_EXPORT', notifyExportProgress);
  }, []);

  const workspaceName = localStorage.getItem('workspace_name');
  const workspaceRoles = currentUser?.workspace?.config?.roles;

  const quotaLink = [
    <Link to="/users/settings#billing" target='_blank' rel='noreferrer'>
      {t('Check subscription')}
    </Link>,
  ];
  
  if (!currentUser) {
    return <Loader />;
  }

  let menuItems = [
    {
      show: workspaceRoles?.includes('menu:search'),
      content: (
        <li key={'menu:search'}>
          <ReloadNavLink to="/search">
            <Tooltip content={t('Search')} position="right">
              <SearchIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Search')}</span>
          </ReloadNavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:narratives'),
      content: (
        <li key={'menu:narratives'}>
          <ReloadNavLink to="/narratives">
            <Tooltip content={t('Cases')} position="right">
              <NarrativesIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Cases')}</span>
          </ReloadNavLink>
        </li>
      ),
    },
    {
      show:
        workspaceRoles?.includes('menu:narratives') &&
        currentUser.type === 'MODERATOR',
      content: (
        <li key={'menu:narratives_moderation'}>
          <NarrativesModerationLink
            itemTextClassname={styles.itemText}
            threatCounterClassname={styles.threatCounter}
          />
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:reports'),
      content: (
        <li key={'menu:reports'}>
          <ReloadNavLink to="/reports">
            <Tooltip content={t('Reports')} position="right">
              <ReportsIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Reports')}</span>
          </ReloadNavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:topic_reports'),
      content: (
        <li key={'menu:topic_reports'}>
          <NavLink to="/topic_reports">
            <Tooltip content={t('Topic discovery')} position="right">
              <TopicIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Topic discovery')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:sources'),
      content: (
        <li key={'menu:sources'}>
          <ReloadNavLink to="/sources">
            <Tooltip content={t('Actors')} position="right">
              <SourcesIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Actors')}</span>
          </ReloadNavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:source_groups'),
      content: (
        <li key={'menu:source_groups'}>
          <ReloadNavLink to="/groups">
            <Tooltip content={t('Actor groups')} position="right">
              <GroupsIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Actor groups')}</span>
          </ReloadNavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:threats'),
      content: (
        <li key={'menu:threats'}>
          <ThreatsLink
            itemTextClassname={styles.itemText}
            threatCounterClassname={styles.threatCounter}
          />
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:dashboards'),
      content: (
        <li key={'menu:dashboards'}>
          <NavLink to="/dashboards">
            <Tooltip content={t('Dashboards')} position="right">
              <DashboardsIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Dashboards')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:user_stats'),
      content: (
        <li key={'menu:user_stats'}>
          <ReloadNavLink to="/user_stats">
            <Tooltip content={t('User stats')} position="right">
              <UserStatsIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('User stats')}</span>
          </ReloadNavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:workspaces'),
      content: (
        <li key={'menu:workspaces'}>
          <ReloadNavLink to="/workspaces">
            <Tooltip content={t('Workspaces')} position="right">
              <WorkspacesIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Workspaces')}</span>
          </ReloadNavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:async_export'),
      content: (
        <li key={'menu:async_export'}>
          <ReloadNavLink to="/exports">
            <Tooltip content={t('Exports')} position="right">
              <ExportIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Exports')}</span>
          </ReloadNavLink>
        </li>
      ),
    },
    {
      show: currentUser.is_admin,
      content: (
        <li key={'menu:users'}>
          <ReloadNavLink to="/users">
            <Tooltip content={t('Users')} position="right">
              <UserIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Users')}</span>
          </ReloadNavLink>
        </li>
      ),
    },
  ];

  if (currentUser?.workspace?.config?.is_reporter_only_user) {
    menuItems = [
      {
        show: workspaceRoles?.includes('menu:threats'),
        content: (
          <li key={'menu:threats'}>
            <ThreatsLink
              itemTextClassname={styles.itemText}
              threatCounterClassname={styles.threatCounter}
            />
          </li>
        ),
      },
    ];
  }

  return (
    <div>
      <input
        type="checkbox"
        id="nav-toggle"
        checked={isNavToogleChecked}
        onChange={() => setisNavToogleChecked(!isNavToogleChecked)}
      />

      <div className="sidebar">
        <div className="sidebar-brand">
          <h1>
            <span>
              <OsavulIcon className="short" style={{ width: '35px' }} />
              <OsavulFullIcon className="full" style={{ width: '100px' }} />
            </span>
          </h1>
        </div>

        <div className="sidebar-menu">
          <ul>
            {menuItems
              .filter((menuItem) => menuItem.show)
              .map((menuItem) => menuItem.content)}
          </ul>
          <div className={styles.userSettings}>
            <button
              type="button"
              className={styles.logoutBtn}
              onClick={logoutHandler}
            >
              <Tooltip content={t('Logout')} position="right">
                <LogoutIcon />
              </Tooltip>
              <span className={styles.itemText}>{t('Logout')}</span>
            </button>
          </div>
        </div>
      </div>
      <div className="main-content">
        <header>
          {workspaceName !== null && (
            <div className={styles.sudoWrapper}>
              <div>{workspaceName}</div>
              <div className="button">
                <button onClick={() => sudoGetOutHandle()}>
                  {t('Get out')}
                </button>
              </div>
            </div>
          )}
          <div className="page-header">
            <h2>
              <label htmlFor="nav-toggle" className={styles.menuLabel}>
                <img src={menuIcon} alt="" />
              </label>
            </h2>

            <div className={styles.userWrapper}>
              <div className={styles.userAvatar}>
                {/* <img  src="/static/me_64.jpg" alt="" /> */}
                <span>{getInitials()}</span>
              </div>
              <div>
                <h4>
                  <Link to="/users/settings">
                    {currentUser.first_name} {currentUser.last_name}
                  </Link>
                </h4>
                <small>
                  {currentUser.is_admin
                    ? t('Administrator')
                    : t(currentUser.type)}
                </small>
              </div>
            </div>
          </div>
        </header>
      </div>

      <main className={workspaceName !== null ? 'sudo' : ''}>
        {maintenanceMessage &&
        !JSON.parse(sessionStorage.getItem('hideMaintenanceMessage')) ? (
          <MaintenanceMessage
            hideKey="hideMaintenanceMessage"
            setHideFlag={setMaintenanceMessage}
            message={maintenanceMessage}
            type='info'
          />
        ) : (
          ''
        )}

        {isAiQuotaLow &&
        !JSON.parse(sessionStorage.getItem('hideLowAIQuotaMessage')) ? (
          <MaintenanceMessage
            hideKey="hideLowAIQuotaMessage"
            setHideFlag={setIsAiQuotaLow}
            message={t(`Your workspace's AI quota is running low. Contact your manager to maintain access to AI-powered features.`)}
            type='warning'
            actions={quotaLink}
          />
        ) : (
          ''
        )}

        {isAIQuotaInsufficient &&
        !JSON.parse(sessionStorage.getItem('hideAIQuotaInsufficientMessage')) ? (
          <MaintenanceMessage
            hideKey="hideAIQuotaInsufficientMessage"
            setHideFlag={setIsAIQuotaInsufficient}
            message={t(`Your workspace has reached its AI quota limit. Contact your manager to request more quota or wait till the next billing month.`)}
            type='critical'
            actions={quotaLink}
          />
        ) : (
          ''
        )}

        {isAnalyticalQuotaLow &&
        !JSON.parse(sessionStorage.getItem('hideLowAnalyticalQuotaMessage')) ? (
          <MaintenanceMessage
            hideKey="hideLowAnalyticalQuotaMessage"
            setHideFlag={setIsAnalyticalQuotaLow}
            message={t(`Your workspace's Analytical quota is running low. Contact your manager to maintain access to analytical features.`)}
            type='warning'
            actions={quotaLink}
          />
        ) : (
          ''
        )}

        {isAnalyticalQuotaInsufficient &&
        !JSON.parse(sessionStorage.getItem('hideAnalyticalQuotaInsufficientMessage')) ? (
          <MaintenanceMessage
            hideKey="hideAnalyticalQuotaInsufficientMessage"
            setHideFlag={setIsAnalyticalQuotaInsufficient}
            message={t(`Your workspace has reached its Analytical quota limit. Contact your manager to request more quota or wait till the next billing month.`)}
            type='critical'
            actions={quotaLink}
          />
        ) : (
          ''
        )}

        {isTrialEnding &&
        !JSON.parse(sessionStorage.getItem('hideTrialEndingMessage')) ? (
          <MaintenanceMessage
            hideKey="hideTrialEndingMessage"
            setHideFlag={setIsTrialEnding}
            message={
              <>
                {t('trialPeriodEnds', { count: calculateTrialDaysLeft() })} {t('Contact your manager to discuss the next steps and ensure uninterrupted access.')}
              </>
            }
            type='warning'
            actions={
              [
                <Link to="/users/settings#billing" target='_blank' rel='noreferrer'>
                  {t('Check subscription')}
                </Link>,
              ]
            }
          />
        ) : (
          ''
        )}

        <Outlet />
        <ToastContainer />
      </main>
    </div>
  );
}
