import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from 'react';

import { Collapsible } from '../../SearchPage/Filter/Collapsible';
import { StatusChooser } from './StatusChooser';
import { FavoriteChooser } from './FavoriteChooser';

import { ReactComponent as FilterIcon } from '../../SearchPage/assets/filter.svg';
import { useClickOutside } from '../../../utils/useClickOutside.jsx';
import '../../SearchPage/Filter/Filter.scss';
import styles from '../../SearchPage/Filter/Filter.module.scss';

export const Filter = ({
  statusesFilter,
  showOnlyFavoritesFilter,
  onChange,
}) => {
  const { t } = useTranslation();
  const clickRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [statuses, setStatuses] = useState(statusesFilter || []);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(
    showOnlyFavoritesFilter || false,
  );
  const [isStatusesOpen, setIsStatusesOpen] = useState(true);
  const [isShowFavoritesOpen, setIsShowFavoritesOpen] = useState(true);

  useEffect(() => {
    setStatuses(statusesFilter || []);
    setShowOnlyFavorites(showOnlyFavoritesFilter || false);
  }, [statusesFilter, showOnlyFavoritesFilter]);

  let filtersSumm = statuses.length;

  useClickOutside(clickRef, () => {
    if (isActive) {
      setIsActive(false);
    }
  });

  return (
    <div ref={clickRef} className={styles.root}>
      <button
        className={
          isActive
            ? `${styles.button} ${styles.filters} ${styles.active}`
            : `${styles.button} ${styles.filters}`
        }
        onClick={() => setIsActive(!isActive)}
      >
        {filtersSumm > 0 ? <span className="summ">{filtersSumm}</span> : ''}
        {t('Filters')}
        <FilterIcon className={isActive ? 'iconActive' : 'icon'} />
      </button>

      <div
        className={
          isActive
            ? `${styles.container} ${styles.show}`
            : `${styles.container}`
        }
      >
        <div className={styles.header}>
          <span>{t('Filter by')}</span>

          <span
            onClick={() => {
              setIsStatusesOpen(!isStatusesOpen);
            }}
          >
            {isStatusesOpen ? `${t('Minimize all')}` : `${t('Expand all')}`}
          </span>
        </div>

        <div className={styles.collapsiblesWrapper}>
          <Collapsible
            name="Favorites"
            isOpen={isShowFavoritesOpen}
            onChange={(open) => setIsShowFavoritesOpen(open)}
          >
            <FavoriteChooser
              checked={showOnlyFavorites}
              onChange={(value) => setShowOnlyFavorites(value)}
            />
          </Collapsible>

          <Collapsible
            name="Status"
            isOpen={isStatusesOpen}
            summ={statuses.length}
            onChange={(open) => setIsStatusesOpen(open)}
          >
            <StatusChooser
              selected={statuses}
              onChange={(checklist) => setStatuses(checklist)}
            />
          </Collapsible>
        </div>

        <div className={styles.controls}>
          <button
            className={`${styles.button} ${styles.apply}`}
            onClick={() => {
              onChange(statuses, showOnlyFavorites);
              setIsActive(false);
            }}
          >
            {t('Apply')}
          </button>

          <button
            className={`${styles.button} ${styles.reset}`}
            onClick={() => {
              setStatuses([]);
              setShowOnlyFavorites(false);
            }}
          >
            {t('Reset all')}
          </button>
        </div>
      </div>
    </div>
  );
};
