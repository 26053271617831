import { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Tooltip.module.scss';

const Tooltip = ({ content, position = 'top', children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = (e) => {
    setIsVisible(true);
  };

  const handleMouseLeave = (e) => {
    setIsVisible(false);
  };

  const getTooltipPositionClass = () => {
    switch (position) {
      case 'top':
        return styles.top;
      case 'bottom':
        return styles.bottom;
      case 'left':
        return styles.left;
      case 'right':
        return styles.right;
      default:
        return styles.top;
    }
  };

  return (
    <div 
      className={styles['tooltip-container']}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`${styles.tooltip} ${isVisible ? styles.visible : styles.hidden} ${getTooltipPositionClass()}`}>
        {content}
      </div>
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  content: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  children: PropTypes.node.isRequired,
};

export default Tooltip;
