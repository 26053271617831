import * as d3 from 'd3';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as InfoIcon } from '../../assets/anchor.svg';
import styles from './UserSettingsPage.module.scss'

const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

const calculatePercentageRemainingQuota = (balance, limit) => {
  if (limit === 0) {
    return 0;
  }
  return ((balance / limit) * 100).toFixed(2);
}

const calculatePercentageUsedQuota = (balance, limit) => {
  if (limit === 0) {
    return 0;
  }
  return (100 - calculatePercentageRemainingQuota(balance, limit)).toFixed(2);
}

export const QuotaProgressBar = ({ 
  value,
  max,
  label,
  resetDate,
  tooltip,
}) => {
  const { t } = useTranslation();

  const remainingPercentage = calculatePercentageRemainingQuota(value, max);

  let progressBarClass = styles.progressGreen;
  if (remainingPercentage <= 30) {
    progressBarClass = styles.progressRed
  };
  if (remainingPercentage <= 65 && remainingPercentage > 30){
    progressBarClass = styles.progressOrange
  };

  return (
    <div className={styles.statusBar}>
      <div className={styles.statusBarHeader}>
        <h4>
          {t(label)}
          {tooltip && (
            <span
              className={styles.tooltipIcon}
              data-tooltip-id="progress-bar-tooltip"
              data-tooltip-content={t(tooltip)}
              data-tooltip-place="bottom-start"
            >
              <InfoIcon />
            </span>
          )}
        </h4>
        <div className={styles.statusBarHeaderRight}>
          <span>
            {formatWithCustomGigaBillion(value)} {t('of')} {formatWithCustomGigaBillion(max)} (
            {calculatePercentageUsedQuota(value, max)}% {t('used')})
          </span>
        </div>
      </div>

      <div className={styles.quotaBar}>
        <progress
          className={`${progressBarClass}`}
          value={value}
          max={max}
        >
          {value}
        </progress>
        <div className={styles.quotaBarLegend}>
          <span>
            {formatWithCustomGigaBillion(value)} ({remainingPercentage}%) {t('Remaining')}
          </span>
          <span>
            {t('The quota resets on')} {' '} {format(new Date(resetDate), "dd-MM-yyyy")}
          </span>
        </div>
      </div>

      <Tooltip id="progress-bar-tooltip" style={{ width: '400px' }}/>
    </div>
  );
  
};
