import { useState, useEffect, useCallback } from 'react';
import { Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { API } from '../../API';
import Modal from '../../components/Modal';
import { Paginator } from '../../components/Paginator/Paginator';
import { Loader } from '../../components/Loader/Loader';
import { Search } from '../../components/Search/Search';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import Tooltip from '../../components/Tooltip/Tooltip';
import styles from './UsersPage.module.scss';

const mapStatusClasses = {
  admin: styles.admin,
  regular: styles.regular,
};

export function UsersPage() {
  const { t } = useTranslation();
  const [users, setUsers] = useState(null);
  const [currentUser] = useCurrentUser();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    isActive: false,
    id: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const searchQuery = searchParams.get('q');
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [sorting, setSorting] = useState({
    isAscending: false,
    fieldName: 'id',
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.reload && location.state?.reload === true) {
      setSorting({ isAscending: false, fieldName: 'id' });
      setUsers(null);
    }
  }, [location.state]);

  const sortingQuery = sorting
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;

  const fetchUsers = useCallback(() => {
    if (!currentUser.is_admin) {
      navigate('/');
      return
    }

    const urlParams = new URLSearchParams();
    urlParams.set('size', '100');
    urlParams.set('page', page);

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }

    if (sorting) {
      if (Array.isArray(sorting.fieldName)) {
        sorting.fieldName.forEach((element) => {
          urlParams.append(
            'sorting',
            `${sorting.isAscending ? '' : '-'}${element}`,
          );
        });
      } else {
        urlParams.set('sorting', sortingQuery);
      }
    }

    API.fetch('GET', `/API/v1/users?${urlParams.toString()}`).then((data) => {
      setUsers(data);
    });
  }, [page, searchQuery, sortingQuery, sorting]);

  const handleDelete = useCallback(
    (id) => {
      setDeleteConfirmModal(true);

      API.fetch('DELETE', `/API/v1/users/${id}`).then(fetchUsers);
    },
    [fetchUsers],
  );

  useEffect(fetchUsers, [fetchUsers]);

  if (!users) {
    return <Loader />;
  }

  const sortingMenu = [
    {
      name: t('Identifier'),
      value: 'id',
    },
    {
      name: t('First name'),
      value: 'first_name',
    },
    {
      name: t('Last name'),
      value: 'last_name',
    },
    {
      name: t('Email'),
      value: 'email',
    },
    {
      name: t('User type'),
      value: 'type',
    },
    {
      name: t('Admin'),
      value: 'is_admin',
    },
  ];

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon />,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon />,
    },
  ];

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <div className="breadcrumb">
            <span>{t('Users')}</span>
          </div>
        </div>
        <div className="controls">
          <Link to="/users/new" className="button new-button">
            <PlusIcon />
            {t('Create user')}
          </Link>
        </div>
      </div>

      <div className="next-card-header">
        <div className={styles.searchWrapper}>
          <Search
            className={styles.searchInput}
            onChange={(value) => {
              setSearchParams({ q: value, page: 1 });
            }}
          />

          <DropdownMenu
            isOpen={dropdownMenu}
            header={t('Sort by')}
            onClick={() => setDropdownMenu(!dropdownMenu)}
            buttonName={t('Sort')}
            icon={<SortIcon />}
          >
            <Radiobutton
              itemsList={sortingMenu}
              current={sorting.fieldName}
              onChange={(value) => setSorting({ ...sorting, fieldName: value })}
            />

            <SortingOrder
              itemsList={sortingOrder}
              onClick={(value) =>
                setSorting({ ...sorting, isAscending: value })
              }
              current={sorting.isAscending}
            />
          </DropdownMenu>
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <table className={styles.users}>
          <thead>
            <tr>
              <td>{t('User')}</td>
              <td>{t('Type')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {users.objects.map((user) => {
              let isNarrativeActive =
                user.is_admin === true ? 'admin' : 'regular';

              return (
                <tr key={user.id}>
                  <td>
                    <div className={styles.userNameWrapper}>
                      <div className={styles.userNameContainer}>
                        <span>
                            {user.first_name} {user.last_name}
                        </span>
                        <div className={styles.userInfo}>
                          <span>#{user.id}</span>
                          <span>&#8226;</span>
                          <div className={styles.statusWrapper}>
                            <span
                              className={`${mapStatusClasses[isNarrativeActive]}`}
                            >
                              {user.is_admin ? t('Admin') : t('Regular')}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{t(user.type)}</td>
                  <td>
                    <div className={styles.controls}>
                      <Tooltip content={t('Edit')} position="bottom">
                        <Link
                          to={`/users/${user.id}/edit`}
                          className="new-button"
                        >
                          <i className="lar la-edit"></i>
                        </Link>
                      </Tooltip>

                      <Tooltip content={t('Delete')} position="bottom">
                        <button
                          className="btn-danger"
                          onClick={() =>
                            setDeleteConfirmModal({
                              isActive: true,
                              id: user.id,
                            })
                          }
                        >
                          <i className="las la-trash"></i>
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Paginator size={users.size} page={users.page} total={users.total} />

      <Modal
        isVisible={deleteConfirmModal.isActive}
        title={t('Delete')}
        content={<p>{t('Are you sure you want to delete a users?')}</p>}
        footer={
          <>
            <button
              className="btn-danger"
              onClick={() => handleDelete(deleteConfirmModal.id)}
            >
              {t('Delete')}
            </button>
            <button
              className="btn-reset"
              onClick={() =>
                setDeleteConfirmModal({ isActive: false, id: null })
              }
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => setDeleteConfirmModal({ isActive: false, id: null })}
      />
    </div>
  );
}
