import { ReactComponent as CloseIcon } from './assets/close.svg';

import styles from './Layout.module.scss';

export function MaintenanceMessage({ hideKey, setHideFlag, message, actions, type='general' }) {
  const hidden = JSON.parse(sessionStorage.getItem(hideKey));
  if (hidden) return null;

  const handleClose = () => {
    setHideFlag(false);
    sessionStorage.setItem(hideKey, JSON.stringify(true));
  };

  let messageClass = styles.maintenanceMessage;
  if (type === 'general') {
    messageClass = styles.maintenanceMessage;
  }

  if (type === 'warning') {
    messageClass = styles.maintenanceMessageWarning;
  }

  if (type === 'critical') {
    messageClass = styles.maintenanceMessageCritical;
  }

  if (type === 'info') {
    messageClass = styles.maintenanceMessageInfo;
  }

  if (type === 'success') {
    messageClass = styles.maintenanceMessageSuccess;
  }
  
  return (
    <div className={messageClass}>
      <div className={styles.maintenanceMessageContainer}>
        <div className={styles.maintenanceMessageText}>{message}</div>
        <div className={styles.maintenanceMessageActions}>
          {actions?.map(action => {
            return action;
          }
          )}
        </div>
      </div>
      <div className={styles.maintenanceMessageClose} onClick={handleClose}>
        <CloseIcon />
      </div>
    </div>
  );
}
